import type { VFC } from 'react';

import Card from '../Card';
import SimpleToggle from '../SimpleToggle';

export interface SharableDossierToggleProps {
  sharableDossiertoggle: boolean;
  setSharableDossierToggle: (toggle: boolean) => void;
}

const SharableDossierToggle: VFC<SharableDossierToggleProps> = ({
  sharableDossiertoggle,
  setSharableDossierToggle,
}) => (
  <Card className="h-fit p-4">
    <SimpleToggle
      label="Enable public dossier link sharing"
      checked={sharableDossiertoggle}
      onChange={(): void => setSharableDossierToggle(!sharableDossiertoggle)}
    />
    <p className="text-sm text-gray-500 mt-4">
      This allows HeadRace recruiters to share this role dossier with
      candidates. Anyone on the internet with this link can view this dossier.
    </p>
  </Card>
);

export default SharableDossierToggle;
