import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { VFC } from 'react';
import { useCallback, useMemo } from 'react';
import type { MultiValue, SingleValue } from 'react-select';

import { BasicSelect } from '../forms';
import type { NavigationItem } from '.';
import { useHeaderTabs } from './HeaderTabsProvider';

export interface OptionsProps {
  label: string;
  value: string;
}

export interface HeaderTabProps {
  className?: string;
}

const HeaderTab: VFC<HeaderTabProps> = ({ className }) => {
  const router = useRouter();

  const { tabs, rightContent } = useHeaderTabs();

  const isCurrentTab = useCallback(
    (navItem: NavigationItem): boolean => {
      if (navItem.href === router.asPath) return true;

      if (navItem.children) return navItem.children.includes(router.asPath);
      return false;
    },
    [router.asPath]
  );
  const handleTabSelectChange = useCallback(
    async (
      newValue: MultiValue<OptionsProps> | SingleValue<OptionsProps>
    ): Promise<void> => {
      const url = newValue;
      if (url && 'value' in url) {
        await router.push(url.value, undefined, { shallow: true });
      }
    },
    [router]
  );

  const optionsSelect = tabs.map((item) => ({
    value: item.href,
    label: item.name,
  }));

  const valueSelect = useMemo((): OptionsProps => {
    const tab = tabs.find(
      (item) =>
        item.href === router.asPath ||
        (item.children && item.children.includes(router.asPath))
    );
    return tab
      ? { value: tab.href, label: tab.name }
      : { value: '', label: '' };
  }, [router.asPath, tabs]);

  return (
    <div
      className={classNames(
        'bg-white sticky top-[4rem] sm:min-h-[4rem] z-20',
        className
      )}
    >
      <div className=" px-2 sm:px-[60px] shadow">
        <div className="border-t border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-center sm:flex-wrap-reverse">
            <nav className="-mb-px hidden sm:flex space-x-10 mr-2">
              {tabs.map((tab) => (
                <Link key={tab.name} href={tab.href} passHref shallow>
                  <a
                    className={classNames(
                      isCurrentTab(tab)
                        ? 'border-headraceYellow-700 text-headraceBlack-800'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 font-medium text-sm py-6 outline-none',
                      { 'pointer-events-none opacity-75': tab.disabled }
                    )}
                  >
                    {tab.name}
                    {tab.pill && (
                      <span className="rounded-[10px] inline-flex ml-2 bg-headraceYellow-700 !h-[20px] px-[10px] text-xs leading-4 text-center text-gray-800 items-center">
                        {tab.pill}
                      </span>
                    )}
                  </a>
                </Link>
              ))}
            </nav>
            <div className="block sm:hidden w-full">
              <BasicSelect
                value={valueSelect}
                options={optionsSelect}
                onChange={handleTabSelectChange}
                showIconDropdown
                className={classNames({
                  'py-2': !rightContent,
                  'pt-2': rightContent,
                })}
              />
            </div>
            {rightContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTab;
