/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import router from 'next/router';
import type { VFC } from 'react';

import type { NavigationItem } from '.';
import type { UserNavigationItem } from './ProfileMenu';
import ProfileMenu from './ProfileMenu';

interface SidebarProps {
  navigation: NavigationItem[];
  active: string;
  userNavigation: UserNavigationItem[];
  profileImg?: string | null;
}

const Sidebar: VFC<SidebarProps> = ({
  navigation,
  active,
  userNavigation,
  profileImg,
}) => {
  const handleRedirect = (url: string): void => {
    router.push(url).catch(null);
  };
  return (
    <div className="hidden sticky h-screen md:flex md:flex-col md:inset-y-0 w-auto z-10">
      <div className="flex flex-col flex-grow px-3 pt-7 bg-headraceBlack-700 overflow-y-auto w-[13rem]">
        <div className="flex h-8 shrink-0 items-center pb-2 w-full justify-center">
          <Link href="/" passHref>
            <a>
              <Image
                className="h-8 w-auto"
                src="/headrace-logo-horizontal.svg"
                alt="HeadRace Logo"
                width={200}
                height={34}
              />
            </a>
          </Link>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {!item.subNavigation ? (
                      <a
                        key={item.name}
                        onClick={(): void => handleRedirect(item.href)}
                        className={classNames(
                          {
                            'bg-headraceBlack-800': item.href === active,
                          },
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-slate-300 cursor-pointer'
                        )}
                      >
                        {item.icon ? (
                          <item.icon
                            className="h-6 w-6 shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        ) : (
                          ''
                        )}
                        {item.name}
                      </a>
                    ) : (
                      <Disclosure as="div" defaultOpen={item.alwaysOpen}>
                        {({ open }): JSX.Element => (
                          <>
                            {item.alwaysOpen ? (
                              <div
                                className={classNames(
                                  {
                                    'bg-headraceBlack-800':
                                      item.href === active,
                                  },
                                  'flex w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-slate-300'
                                )}
                              >
                                {item.icon ? (
                                  <item.icon
                                    className="h-6 w-6 shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ''
                                )}
                                {item.name}
                              </div>
                            ) : (
                              <Disclosure.Button
                                className={classNames(
                                  {
                                    'bg-headraceBlack-800':
                                      item.href === active,
                                  },
                                  'flex w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-slate-300'
                                )}
                              >
                                {item.icon ? (
                                  <item.icon
                                    className="h-6 w-6 shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ''
                                )}
                                {item.name}
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? 'rotate-90 text-gray-500'
                                      : 'text-gray-400',
                                    'ml-auto h-5 w-5 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                            )}

                            <Disclosure.Panel
                              as="ul"
                              className={classNames(
                                'mt-1 px-2 overflow-y-auto',
                                {
                                  'max-h-[calc(100vh-230px)]': item.fullHeight,
                                }
                              )}
                            >
                              {(item.subNavigation || []).map((subItem) => (
                                <li key={subItem.name}>
                                  {/* 44px */}
                                  <button
                                    type="button"
                                    onClick={(): void =>
                                      handleRedirect(subItem.href)
                                    }
                                    className={classNames(
                                      {
                                        'bg-headraceBlack-800': active.includes(
                                          subItem.href
                                        ),
                                        'font-medium': active.includes(
                                          subItem.href
                                        ),
                                      },
                                      'block text-left rounded-md py-2 text-sm leading-6 text-slate-300 px-1  active:outline-none outline-none w-full hover:bg-headraceBlack-800'
                                    )}
                                  >
                                    {subItem.name}
                                  </button>
                                </li>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
        <div className="flex w-full justify-left">
          <ProfileMenu
            userNavigation={userNavigation}
            profileImg={profileImg}
            positionClassName="left-16 sm:left-32"
            withLabel
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
