import type { CompanyTeamCardData } from '@headrace/types';
import classNames from 'classnames';
import type { VFC } from 'react';
import React, { useMemo } from 'react';

import type { CompanyOverviewCardProps } from './CompanyOverviewCard';
import CompanyOverviewCard from './CompanyOverviewCard';
import type { CompanyPerksCardProps } from './CompanyPerksCard';
import CompanyPerksCard from './CompanyPerksCard';
import type { CompanyPressCardProps } from './CompanyPressCard';
import CompanyPressCard from './CompanyPressCard';
import CompanyTeamCard from './CompanyTeamCard';
import type { GlobalSubmissionsProps } from './GlobalSubmissions';
import GlobalSubmissions from './GlobalSubmissions';
import type { RoleDossierProductSectionProps } from './ProductSection';
import ProductSection from './ProductSection';
import type { RoleDescriptionCardProps } from './RoleDescriptionCard';
import RoleDescriptionCard from './RoleDescriptionCard';
import type { RoleFactsCardProps } from './RoleFactsCard';
import RoleFactsCard from './RoleFactsCard';
import type { RoleOverViewCardProps } from './RoleOverviewCard';
import RoleOverviewCard from './RoleOverviewCard';
import type { RoleRequirementCardProps } from './RoleRequirementsCard';
import RoleRequirementsCard from './RoleRequirementsCard';
import SharableDossierToggle from './SharableDossierToggle';

interface RoleDossierProps {
  editMode?: boolean;
  displayDate?: string | Date;
  roleDescription: RoleDescriptionCardProps;
  companyOverview: CompanyOverviewCardProps;
  roleRequirements: RoleRequirementCardProps;
  companyPerks: CompanyPerksCardProps;
  companyTeam: CompanyTeamCardData;
  companyPress: CompanyPressCardProps;
  globalSubmissions?: GlobalSubmissionsProps | null;
  roleOverview: RoleOverViewCardProps;
  productSection?: RoleDossierProductSectionProps | null | JSX.Element;
  roleFacts: RoleFactsCardProps;
  searchTermsComponent?: JSX.Element | null;
  rightTopComponent?: JSX.Element | null;
  sharableDossiertoggle?: boolean;
  setSharableDossierToggle?: (toggle: boolean) => void;
  loading?: boolean;
  companyDescription?: string | null;
  isHeadRaceAI?: boolean;
}

const RoleDossier: VFC<RoleDossierProps> = ({
  roleDescription,
  editMode,
  companyOverview,
  roleRequirements,
  companyPerks,
  companyTeam,
  companyPress,
  globalSubmissions,
  roleOverview,
  productSection,
  roleFacts,
  searchTermsComponent,
  rightTopComponent,
  displayDate,
  sharableDossiertoggle,
  setSharableDossierToggle,
  loading,
  companyDescription,
  isHeadRaceAI,
}) => {
  const productSectionComponent = useMemo(() => {
    if (!productSection) {
      return null;
    }
    if ('name' in productSection) {
      return (
        <ProductSection
          name={productSection.name}
          marketplaceVisibility={productSection.marketplaceVisibility}
          amount={productSection.amount}
          primaryButton={productSection.primaryButton}
          secondaryButton={productSection.secondaryButton}
          createdAt={productSection.createdAt}
          fee={productSection.fee}
          feeType={productSection.feeType}
          salaryMin={productSection.salaryMin}
          salaryMax={productSection.salaryMax}
        />
      );
    }
    if (React.isValidElement(productSection)) {
      return productSection;
    }
    return null;
  }, [productSection]);
  return (
    <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-5">
      <div
        className={classNames(
          'lg:col-span-2 sm:col-span-full flex flex-col gap-5',
          { 'order-2 xl:order-1': rightTopComponent }
        )}
      >
        <RoleOverviewCard
          editMode={editMode}
          displayDate={displayDate}
          data={roleOverview.data}
          saveAction={roleOverview.saveAction}
          cancelAction={roleOverview.cancelAction}
          loading={roleOverview.loading}
          createMediaUploadLink={roleOverview.createMediaUploadLink}
          roleFunctions={roleOverview.roleFunctions}
          companyStages={roleOverview.companyStages}
          citiesOptions={roleOverview.citiesOptions}
          hiringManagerOptions={roleOverview.hiringManagerOptions}
          isHeadRaceAI={isHeadRaceAI}
        />
        {productSectionComponent != null && (
          <div className="md:block sm:block lg:hidden">
            {productSectionComponent}
          </div>
        )}
        {searchTermsComponent}
        {(roleDescription.description ||
          roleDescription.descriptionJSON ||
          editMode) && (
          <RoleDescriptionCard
            saveAction={roleDescription.saveAction}
            cancelAction={roleDescription.cancelAction}
            loading={roleDescription.loading}
            description={roleDescription.description}
            descriptionJSON={roleDescription.descriptionJSON}
            editMode={editMode}
          />
        )}
        {(companyOverview.overviewJSON || companyDescription || editMode) && (
          <CompanyOverviewCard
            saveAction={companyOverview.saveAction}
            cancelAction={companyOverview.cancelAction}
            loading={companyOverview.loading}
            overviewJSON={companyOverview.overviewJSON}
            editMode={editMode}
            refetchLoading={loading}
            companyDescription={companyDescription}
          />
        )}
        {(companyTeam.members.length > 0 || editMode) && (
          <CompanyTeamCard
            saveAction={companyTeam.saveAction}
            cancelAction={companyTeam.cancelAction}
            loading={companyTeam.loading}
            members={companyTeam.members}
            editMode={editMode}
            refetchLoading={loading}
          />
        )}
        {(companyPerks.perks.length > 0 || editMode) && (
          <CompanyPerksCard
            perks={companyPerks.perks}
            cancelAction={companyPerks.cancelAction}
            editMode={editMode}
            loading={companyPerks.loading}
            saveAction={companyPerks.saveAction}
            companyName={companyPerks.companyName}
            refetchLoading={loading}
          />
        )}
      </div>
      <div
        className={classNames(
          'lg:col-span-1 sm:col-span-full flex flex-col gap-5',
          { 'sm:order-1 xl:order-2': rightTopComponent }
        )}
      >
        {rightTopComponent}
        {productSectionComponent != null && (
          <div className="md:hidden sm:hidden lg:block">
            {productSectionComponent}
          </div>
        )}
        {globalSubmissions && (
          <GlobalSubmissions
            error={globalSubmissions.error}
            loading={globalSubmissions.loading}
            data={globalSubmissions.data}
          />
        )}
        {setSharableDossierToggle && (
          <SharableDossierToggle
            sharableDossiertoggle={sharableDossiertoggle || false}
            setSharableDossierToggle={setSharableDossierToggle}
          />
        )}
        <RoleFactsCard
          editMode={editMode}
          roleSeniorityOptions={roleFacts.roleSeniorityOptions}
          saveAction={roleFacts.saveAction}
          cancelAction={roleFacts.cancelAction}
          loading={roleFacts.loading}
          data={roleFacts.data}
        />
        {([
          ...roleRequirements.niceToHaveRequirements,
          ...roleRequirements.mustToHaveRequirements,
        ].length > 0 ||
          editMode) && (
          <RoleRequirementsCard
            saveAction={roleRequirements.saveAction}
            cancelAction={roleRequirements.cancelAction}
            loading={roleRequirements.loading}
            niceToHaveRequirements={roleRequirements.niceToHaveRequirements}
            mustToHaveRequirements={roleRequirements.mustToHaveRequirements}
            editMode={editMode}
            refetchLoading={loading}
          />
        )}
        {(companyPress.pressList.length > 0 || editMode) && (
          <CompanyPressCard
            pressList={companyPress.pressList}
            cancelAction={companyPress.cancelAction}
            editMode={editMode}
            loading={companyPress.loading}
            saveAction={companyPress.saveAction}
            refetchLoading={loading}
          />
        )}
      </div>
    </div>
  );
};

export * from './ProductSection';

export default RoleDossier;
