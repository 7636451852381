import type {
  RoleRequirementFormValues,
  RoleRequirementValue,
} from '@headrace/types';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { FieldArray, useFormikContext } from 'formik';
import type { VFC } from 'react';

import { TextArea } from '../../forms';

interface Props {
  originalRequirements: RoleRequirementValue[];
  values: RoleRequirementValue[];
  deletedRequirements: string[];
  initialValues: RoleRequirementValue;
  name: string;
  title: string;
}

const RoleRequirementsCardForm: VFC<Props> = ({
  originalRequirements,
  values,
  deletedRequirements,
  initialValues,
  name,
  title,
}) => {
  const { setFieldValue } = useFormikContext<RoleRequirementFormValues>();
  return (
    <FieldArray name={name} validateOnChange={false}>
      {({ push, remove }): JSX.Element => (
        <div className="flex flex-col gap-4">
          <div className="text-headraceBlack-700 font-medium text-base">
            {title}
          </div>
          <div className="flex flex-col gap-4">
            {values.map((requirement, index) => (
              <div key={requirement.id} className="flex gap-3 items-start">
                <TextArea
                  name={`${name}.${index}.prompt`}
                  containerClassName="w-full"
                />
                <button
                  type="button"
                  onClick={(): void => {
                    remove(index);
                    const originalRequirement = originalRequirements.find(
                      (or) => or.id === requirement.id
                    );
                    if (originalRequirement) {
                      setFieldValue(
                        'deletedRequirements',
                        deletedRequirements
                          ? deletedRequirements.concat(originalRequirement.id)
                          : [originalRequirement.id]
                      );
                    }
                  }}
                >
                  <TrashIcon className="w-5 h-5 stroke-blue-500" />
                </button>
              </div>
            ))}
          </div>
          <button
            type="button"
            className="py-4 flex flex-col justify-center items-center border-2 border-dashed border-gray-200 rounded-md w-full"
            onClick={(): void => {
              push(initialValues);
            }}
          >
            <div className="flex items-center gap-2">
              <PlusIcon className="w-5 h-5 text-gray-400" />
              <span className="whitespace-nowrap text-headraceBlack-700 font-medium text-sm">
                Add requirements
              </span>
            </div>
          </button>
        </div>
      )}
    </FieldArray>
  );
};

export default RoleRequirementsCardForm;
