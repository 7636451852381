import type { CandidateActivityHistoryActivityType } from '@headrace/types';
import type { CandidateActivityHistoryEntityType } from '@headrace/types';
import type { CandidateDocumentTypeEnum } from '@headrace/types';
import type { CandidateExpirationReasonEnum } from '@headrace/types';
import type { CandidateInterestVerificationType } from '@headrace/types';
import type { CandidateRejectedReasonEnum } from '@headrace/types';
import type { CollaborationStatus } from '@headrace/types';
import type { CompanyDataIngestSource } from '@headrace/types';
import type { CompanyPerkEnum } from '@headrace/types';
import type { EnrichmentProspectStatus } from '@headrace/types';
import type { EntityTypeEnum } from '@headrace/types';
import type { EvaluationStatus } from '@headrace/types';
import type { ExpectedPlacementsEnum } from '@headrace/types';
import type { FeeAdjustmentTypeEnum } from '@headrace/types';
import type { FeeScheduleTypeEnum } from '@headrace/types';
import type { FeeTriggerTypeEnum } from '@headrace/types';
import type { InvitationStatus } from '@headrace/types';
import type { LatestFundingRoundTypeEnum } from '@headrace/types';
import type { LeadStatusEnum } from '@headrace/types';
import type { MarketplaceRoleRecruiterStatus } from '@headrace/types';
import type { MarketplaceVisibilityEnum } from '@headrace/types';
import type { MeetRequirementEnum } from '@headrace/types';
import type { MessageDirectionType } from '@headrace/types';
import type { OnboardingStatus } from '@headrace/types';
import type { OutreachChannelEnum } from '@headrace/types';
import type { OutreachMessageType } from '@headrace/types';
import type { OutreachSequenceStatus } from '@headrace/types';
import type { PayoutTypeEnum } from '@headrace/types';
import type { PricingTypeEnum } from '@headrace/types';
import type { ProspectAssigneeEnum } from '@headrace/types';
import type { ProspectSearchType } from '@headrace/types';
import type { ProspectStatus } from '@headrace/types';
import type { RecruiterSearchAgreementActivityCategory } from '@headrace/types';
import type { RemoteWorkPolicyEnum } from '@headrace/types';
import type { RoleCollaboratorTypeEnum } from '@headrace/types';
import type { RoleQuestionStatus } from '@headrace/types';
import type { RoleTypeEnum } from '@headrace/types';
import type { SearchPreferenceEnum } from '@headrace/types';
import type { UserNotificationChannel } from '@headrace/types';
import type { UserNotificationEnum } from '@headrace/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AcceptanceRateMetricsOutput = {
  __typename?: 'AcceptanceRateMetricsOutput';
  quartileAcceptanceRate: Scalars['Float'];
  recruiterAcceptanceRate: Scalars['Float'];
};

export type AcceptedLead = {
  __typename?: 'AcceptedLead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementCreatedId?: Maybe<Scalars['String']>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Agency = {
  __typename?: 'Agency';
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  isTestAccount: Scalars['Boolean'];
  leads: Array<Lead>;
  linkedIn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payouts: Array<Payout>;
  recruiters: Array<Recruiter>;
  searchAgreements: Array<SearchAgreement>;
  singularEmployers: Array<Employer>;
  slackChannelAbbreviation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
};

export type AgencyEarning = {
  __typename?: 'AgencyEarning';
  id: Scalars['String'];
  placementRevenue: Scalars['Float'];
  retainerRevenue: Scalars['Float'];
};

export type AgencyEarningsInput = {
  endDate?: InputMaybe<Scalars['String']>;
  periodicity: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type AgencyMonthlyEarning = {
  __typename?: 'AgencyMonthlyEarning';
  month: Scalars['String'];
  placementEarnings: Scalars['Float'];
  retainerEarnings: Scalars['Float'];
  totalEarnings: Scalars['Float'];
};

export type AtsIntegration = {
  __typename?: 'AtsIntegration';
  accountToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  employer: Employer;
  id: Scalars['ID'];
  mergeJobs: MergeJob;
  name: Scalars['String'];
  shareCandidateFeedback: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  userEmail: Scalars['String'];
};

export type Candidate = {
  __typename?: 'Candidate';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidateAcceptOfferInput = {
  baseSalary?: InputMaybe<Scalars['Float']>;
  candidateId: Scalars['String'];
  shareCount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CandidateActivityHistoryActivityType>;
};

export type CandidateActivityHistory = {
  __typename?: 'CandidateActivityHistory';
  activityMeta?: Maybe<CandidateActivityHistoryActivityMetaType>;
  activityType: CandidateActivityHistoryActivityType;
  candidate: Candidate;
  candidateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId?: Maybe<Scalars['String']>;
  entityType: CandidateActivityHistoryEntityType;
  eventOccurredAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type CandidateActivityHistoryActivityMetaType = {
  __typename?: 'CandidateActivityHistoryActivityMetaType';
  expirationReasonCategory?: Maybe<CandidateExpirationReasonEnum>;
  mergeInterviewId?: Maybe<Scalars['String']>;
  mergeRejectReasonId?: Maybe<Scalars['String']>;
  rejectReasonCategory?: Maybe<CandidateRejectedReasonEnum>;
  rejectReasonDetails?: Maybe<Scalars['String']>;
  shareRejectionInfo?: Maybe<Scalars['Boolean']>;
};

export { CandidateActivityHistoryActivityType };

export { CandidateActivityHistoryEntityType };

export type CandidateActivityHistoryFilter = {
  candidateId: Scalars['String'];
};

export type CandidateActivityHistoryOutput = {
  __typename?: 'CandidateActivityHistoryOutput';
  candidateHistory: Array<CandidateActivityHistory>;
};

export type CandidateDetailsOutput = {
  __typename?: 'CandidateDetailsOutput';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  feeSplitPercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCollaborationCandidate?: Maybe<Scalars['Boolean']>;
  isCollaborationRecruiter: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidateDocument = {
  __typename?: 'CandidateDocument';
  candidate: Candidate;
  candidateId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  document: Scalars['String'];
  documentPath?: Maybe<Scalars['String']>;
  documentType?: Maybe<CandidateDocumentTypeEnum>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { CandidateDocumentTypeEnum };

export { CandidateExpirationReasonEnum };

export { CandidateInterestVerificationType };

export type CandidateMarketPlaceMetricsOutput = {
  __typename?: 'CandidateMarketPlaceMetricsOutput';
  hiredCount: Scalars['Float'];
  inReviewCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
};

export type CandidateMetrics = {
  __typename?: 'CandidateMetrics';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export { CandidateRejectedReasonEnum };

export type CandidateRoleRequirement = {
  __typename?: 'CandidateRoleRequirement';
  aiMeetRequirement?: Maybe<Scalars['String']>;
  aiMeetRequirementExplanation?: Maybe<Scalars['String']>;
  candidate: Candidate;
  candidateExplanation?: Maybe<Scalars['String']>;
  candidateMeetsRequirement?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  meetRequirement?: Maybe<MeetRequirementEnum>;
  meetRequirementOverride?: Maybe<MeetRequirementEnum>;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  roleRequirement?: Maybe<RoleRequirement>;
  roleRequirementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vellumMeetRequirement?: Maybe<Scalars['String']>;
  vellumMeetRequirementExplanation?: Maybe<Scalars['String']>;
};

export type CandidateStatusMetricsOutput = {
  __typename?: 'CandidateStatusMetricsOutput';
  hiredCount: Scalars['Float'];
  interviewingCount: Scalars['Float'];
  submittedCount: Scalars['Float'];
};

export type CandidateWithMetrics = {
  __typename?: 'CandidateWithMetrics';
  candidateMetrics: CandidateMetrics;
  candidates: Array<CandidateWithSearchAgreement>;
  candidatesLast7Days?: Maybe<Array<Candidate>>;
  globalCandidateMetrics?: Maybe<CandidateMetrics>;
  submissionAvailableDate?: Maybe<Scalars['String']>;
};

export type CandidateWithSearchAgreement = {
  __typename?: 'CandidateWithSearchAgreement';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  marketplaceVisibility: Scalars['String'];
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type CandidatesByAgency = {
  __typename?: 'CandidatesByAgency';
  candidateMetrics: CandidateMetrics;
  candidates: Array<CandidatesWithDates>;
  recruitersByAgencyCount: Scalars['Float'];
};

export type CandidatesWithDates = {
  __typename?: 'CandidatesWithDates';
  baseSalary?: Maybe<Scalars['Float']>;
  candidateActivityHistories: Array<CandidateActivityHistory>;
  candidateInterestVerificationStatus: CandidateInterestVerificationType;
  collaboration?: Maybe<Collaboration>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  documents?: Maybe<Array<CandidateDocument>>;
  fee?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCollaborationCandidate: Scalars['Boolean'];
  isExpiringSoon: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  justificationJSON?: Maybe<Scalars['String']>;
  lastInterestVerificationSentAt?: Maybe<Scalars['DateTime']>;
  mergeApplication?: Maybe<MergeApplication>;
  recruiter?: Maybe<Recruiter>;
  recruiterId: Scalars['String'];
  roleRequirements?: Maybe<Array<CandidateRoleRequirement>>;
  searchAgreementRole: SearchAgreementRole;
  searchAgreementRoleId: Scalars['String'];
  shareCount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: CandidateActivityHistoryActivityType;
  statusWithInterestVerification: Scalars['String'];
  submitDate: Scalars['DateTime'];
  talent: Talent;
  updatedAt: Scalars['DateTime'];
};

export type City = {
  __typename?: 'City';
  cityCode: Scalars['ID'];
  cityName: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placements: Array<Placement>;
  region?: Maybe<Scalars['String']>;
  stateAbbrev: Scalars['String'];
  stateName: Scalars['String'];
  timezone?: Maybe<Timezone>;
  timezoneId?: Maybe<Scalars['String']>;
};

export type ClientCompanyStage = {
  __typename?: 'ClientCompanyStage';
  count: Scalars['Float'];
  name: Scalars['String'];
};

export type ClosedSearchProduct = {
  __typename?: 'ClosedSearchProduct';
  closedSearchAgreement?: Maybe<SearchAgreement>;
  closedSearchProductMarketplaceRolePrice?: Maybe<PlacementFeeOutput>;
};

export type Collaboration = {
  __typename?: 'Collaboration';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCandidateOwnerAgnostic?: Maybe<Scalars['Boolean']>;
  primaryRecruiter: Recruiter;
  recruiter: Recruiter;
  searchAgreementRole: SearchAgreementRole;
  splitPercentage: Scalars['Float'];
  status: CollaborationStatus;
  updatedAt: Scalars['DateTime'];
};

export type CollaborationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleId: Scalars['String'];
  searchAgreementRoleId: Scalars['String'];
  splitPercentage: Scalars['Float'];
};

export { CollaborationStatus };

export type CommonEducationInstitution = {
  __typename?: 'CommonEducationInstitution';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
};

export type CommonIndustries = {
  __typename?: 'CommonIndustries';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
  placementsCount: Scalars['Float'];
};

export type Company = {
  __typename?: 'Company';
  companyDataIngestRaw?: Maybe<Array<CompanyDataIngestRaw>>;
  companyStage?: Maybe<CompanyStage>;
  companyStageId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  crunchbase?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: Maybe<Industry>;
  industryId?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  introJSON?: Maybe<Scalars['String']>;
  investors?: Maybe<Scalars['String']>;
  latestFundingRoundAmount?: Maybe<Scalars['Float']>;
  latestFundingRoundType?: Maybe<LatestFundingRoundTypeEnum>;
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  logo?: Maybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  overviewJSON?: Maybe<Scalars['String']>;
  perks: Array<CompanyPerk>;
  placements: Array<Placement>;
  press: Array<CompanyPress>;
  revenue?: Maybe<Scalars['Float']>;
  tagline?: Maybe<Scalars['String']>;
  tam?: Maybe<Scalars['Float']>;
  teamMembers: Array<CompanyTeamMember>;
  totalFunding?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
  yoyGrowth?: Maybe<Scalars['Float']>;
};

export type CompanyDataIngestRaw = {
  __typename?: 'CompanyDataIngestRaw';
  company?: Maybe<Array<Company>>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  linkedInCategories?: Maybe<Array<Scalars['String']>>;
  linkedInCompanySizeOnLinkedIn?: Maybe<Scalars['Float']>;
  linkedInCompanyType?: Maybe<Scalars['String']>;
  linkedInCrunchbaseProfileUrl?: Maybe<Scalars['String']>;
  linkedInDescription?: Maybe<Scalars['String']>;
  linkedInFoundedYear?: Maybe<Scalars['Int']>;
  linkedInFundingData?: Maybe<Array<LinkedInFundingRoundMetaType>>;
  linkedInHq?: Maybe<LinkedInCompanyLocationMeta>;
  linkedInIndustry?: Maybe<Scalars['String']>;
  linkedInTagline?: Maybe<Scalars['String']>;
  linkedInTotalFundingAmount?: Maybe<Scalars['Float']>;
  linkedInWebsite?: Maybe<Scalars['String']>;
  source: CompanyDataIngestSource;
  updatedAt: Scalars['DateTime'];
};

export { CompanyDataIngestSource };

export type CompanyPerk = {
  __typename?: 'CompanyPerk';
  company: Company;
  id: Scalars['ID'];
  type: CompanyPerkEnum;
};

export { CompanyPerkEnum };

export type CompanyPress = {
  __typename?: 'CompanyPress';
  company: Company;
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CompanyStage = {
  __typename?: 'CompanyStage';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type CompanyTeamMember = {
  __typename?: 'CompanyTeamMember';
  company: Company;
  id: Scalars['ID'];
  linkedin: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
};

export type CreateCandidateDocumentInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  document: Scalars['String'];
  documentType: CandidateDocumentTypeEnum;
  name: Scalars['String'];
};

export type CreateCandidateInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  candidateVerification: Scalars['Boolean'];
  documents: Array<CreateCandidateDocumentInput>;
  justification: Scalars['String'];
  justificationJSON: Scalars['String'];
  roleId: Scalars['String'];
  roleRequirements?: InputMaybe<Array<CreateCandidateRoleRequirementInput>>;
  talent: CreateTalentInput;
};

export type CreateCandidateRoleRequirementInput = {
  explanation?: InputMaybe<Scalars['String']>;
  meetRequirement: MeetRequirementEnum;
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
};

export type CreateCompanyInput = {
  companyStage: Scalars['String'];
  industry: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateFeeScheduleInput = {
  feeAdjustmentType?: InputMaybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: InputMaybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  oneTimeFeeRelativeSigningDays?: InputMaybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: InputMaybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: InputMaybe<Scalars['Int']>;
};

export type CreateMediaUploadLinkInput = {
  filename: Scalars['String'];
  folder: Scalars['String'];
};

export type CreateRecruiterInput = {
  agencyName?: InputMaybe<Scalars['String']>;
  tou: Scalars['Boolean'];
  user: CreateUserInput;
};

export type CreateRoleInput = {
  cities: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expectedFirstYearSalaryMax?: InputMaybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: InputMaybe<Scalars['Float']>;
  expectedFirstYearShareCount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newRole?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Int']>;
  roleFunction?: InputMaybe<Scalars['String']>;
  roleSeniority?: InputMaybe<Scalars['String']>;
};

export type CreateRoleQuestionInput = {
  question: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreateRoleRequirementInput = {
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreateSearchAgreementRecruiterInput = {
  beginAt: Scalars['DateTime'];
  employerId: Scalars['String'];
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates: Scalars['String'];
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<CreateFeeScheduleInput>;
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  jobDescriptionLink?: InputMaybe<Scalars['String']>;
  replacementSearchNotificationWindowDays?: InputMaybe<Scalars['Int']>;
  replacementSearchTerminateDays?: InputMaybe<Scalars['Int']>;
  replacementSearchWindowMonths?: InputMaybe<Scalars['Int']>;
  roles: Array<CreateRoleInput>;
  signedByEmployeeId: Scalars['String'];
};

export type CreateTalentInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CurrentRecruiter = {
  __typename?: 'CurrentRecruiter';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showMarketplace: Scalars['Boolean'];
  showProfile: Scalars['Boolean'];
  showSearches: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type DetailedLead = {
  __typename?: 'DetailedLead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Document = {
  documentType: CandidateDocumentTypeEnum;
  id: Scalars['String'];
};

export type DownloadDocumentsResultOutput = {
  __typename?: 'DownloadDocumentsResultOutput';
  data: Scalars['String'];
};

export type EarningsMetricsOutput = {
  __typename?: 'EarningsMetricsOutput';
  current: Scalars['Float'];
  hasData: Scalars['Boolean'];
  previous?: Maybe<Scalars['Float']>;
};

export type EducationDegree = {
  __typename?: 'EducationDegree';
  id: Scalars['ID'];
  placements: Array<Placement>;
  type: Scalars['String'];
};

export type EducationInstitution = {
  __typename?: 'EducationInstitution';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type EducationMajor = {
  __typename?: 'EducationMajor';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
};

export type Employee = {
  __typename?: 'Employee';
  acceptedLeads: Array<Lead>;
  canceledSearchAgreements: Array<SearchAgreement>;
  createdAt: Scalars['DateTime'];
  employer: Employer;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  id: Scalars['ID'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  requestedRoles: Array<Role>;
  roles: Array<Role>;
  signedSearchAgreements: Array<SearchAgreement>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type Employer = {
  __typename?: 'Employer';
  company: Company;
  createdAt: Scalars['DateTime'];
  defaultMarketplacePrice: Scalars['Int'];
  employees: Array<Employee>;
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  frontChannelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ignoreForRecruiterAcceptanceRate: Scalars['Boolean'];
  isHeadRaceAI: Scalars['Boolean'];
  isTestAccount: Scalars['Boolean'];
  leads: Array<Lead>;
  outreachChannel: OutreachChannelEnum;
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  primaryAgency?: Maybe<Agency>;
  primaryAgencyId?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  searchAgreements: Array<SearchAgreement>;
  slackChannelAbbreviation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EmployerInvitationByRecruiter = {
  __typename?: 'EmployerInvitationByRecruiter';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  employer: Employer;
  id: Scalars['ID'];
  recruiter: Recruiter;
  status: InvitationStatus;
};

export type EmployerInvitationByRecruiterInput = {
  employeeEmail: Scalars['String'];
  employeeFirstName: Scalars['String'];
  employeeLastName: Scalars['String'];
  employeePhone?: InputMaybe<Scalars['String']>;
  employerName: Scalars['String'];
};

export type Endorsement = {
  __typename?: 'Endorsement';
  endorsement: Scalars['String'];
  endorserCompany: Scalars['String'];
  endorserTitle: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Float'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
};

export { EnrichmentProspectStatus };

export { EntityTypeEnum };

export { EvaluationStatus };

export { ExpectedPlacementsEnum };

export { FeeAdjustmentTypeEnum };

export type FeeScheduleInput = {
  feeAdjustmentType?: InputMaybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: InputMaybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  oneTimeFeeRelativeSigningDays?: InputMaybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: InputMaybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: InputMaybe<Scalars['Int']>;
};

export { FeeScheduleTypeEnum };

export { FeeTriggerTypeEnum };

export type FrontEmailMessage = {
  __typename?: 'FrontEmailMessage';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  from: Scalars['String'];
  frontConversationId: Scalars['String'];
  frontConversationLink: Scalars['String'];
  frontMessageId: Scalars['String'];
  id: Scalars['ID'];
  outreachMessage?: Maybe<OutreachMessage>;
  outreachMessageId?: Maybe<Scalars['String']>;
  outreachSequence: OutreachSequence;
  outreachSequenceId: Scalars['String'];
  to: Scalars['String'];
  type: MessageDirectionType;
};

export type IdealCandidateProfile = {
  __typename?: 'IdealCandidateProfile';
  id: Scalars['ID'];
  linkedIn: Scalars['String'];
  prospectSearch?: Maybe<ProspectSearch>;
  prospectSearchId?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
};

export type Industry = {
  __typename?: 'Industry';
  companies: Array<Company>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { InvitationStatus };

export type InviteTeamMemberInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export { LatestFundingRoundTypeEnum };

export type Lead = {
  __typename?: 'Lead';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type LeadOutput = {
  __typename?: 'LeadOutput';
  acceptedByEmployee?: Maybe<Employee>;
  acceptedByEmployeeId?: Maybe<Scalars['String']>;
  agency: Agency;
  agencyId: Scalars['String'];
  collaboration?: Maybe<Collaboration>;
  collaborationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayStatus: Scalars['String'];
  employerStatus?: Maybe<LeadStatusEnum>;
  id: Scalars['ID'];
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  recruiterStatus: LeadStatusEnum;
  requestedRecruiter?: Maybe<Recruiter>;
  requestedRecruiterId?: Maybe<Scalars['String']>;
  role: Role;
  searchAgreement?: Maybe<SearchAgreement>;
  searchAgreementId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export { LeadStatusEnum };

export type LinkedInCompanyLocationMeta = {
  __typename?: 'LinkedInCompanyLocationMeta';
  city: Scalars['String'];
  country: Scalars['String'];
  is_hq: Scalars['Boolean'];
  line_1: Scalars['String'];
  postal_code: Scalars['String'];
  state: Scalars['String'];
};

export type LinkedInDateMetaType = {
  __typename?: 'LinkedInDateMetaType';
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type LinkedInFundingRoundMetaType = {
  __typename?: 'LinkedInFundingRoundMetaType';
  announced_date: LinkedInDateMetaType;
  funding_type: Scalars['String'];
  investor_list: Array<LinkedInInvestorMetaType>;
  money_raised: Scalars['Float'];
  number_of_investor: Scalars['Int'];
};

export type LinkedInInvestorMetaType = {
  __typename?: 'LinkedInInvestorMetaType';
  linkedin_profile_url: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type LinkedInProfile = {
  __typename?: 'LinkedInProfile';
  agency: Agency;
  agencyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  html: Scalars['String'];
  id: Scalars['ID'];
  parsedLinkedInProfile?: Maybe<ParsedLinkedInProfile>;
  talent?: Maybe<Talent>;
  talentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlPath: Scalars['String'];
};

export type LinkedInProfileRaw = {
  __typename?: 'LinkedInProfileRaw';
  background_cover_image_url: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Scalars['JSON']>>;
  experiences?: Maybe<Array<Scalars['JSON']>>;
  firstName: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  lastRefreshDate: Scalars['DateTime'];
  last_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  occupation?: Maybe<Scalars['String']>;
  profile_pic_url?: Maybe<Scalars['String']>;
  public_identifier: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type MarketplaceRoleOutput = {
  __typename?: 'MarketplaceRoleOutput';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  fee: Scalars['Float'];
  feeGuidance?: Maybe<Scalars['String']>;
  feeType?: Maybe<FeeScheduleTypeEnum>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type MarketplaceRoleRecruiter = {
  __typename?: 'MarketplaceRoleRecruiter';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  recruiter: Recruiter;
  role: Role;
  status: MarketplaceRoleRecruiterStatus;
  updatedAt: Scalars['DateTime'];
};

export { MarketplaceRoleRecruiterStatus };

export type MarketplaceRoleWithStatus = {
  __typename?: 'MarketplaceRoleWithStatus';
  createdAt: Scalars['DateTime'];
  globalCandidateCount: Scalars['Int'];
  role: Role;
  searchInfo: SearchInfo;
  status: MarketplaceRoleRecruiterStatus;
};

export { MarketplaceVisibilityEnum };

export type MediaUploadLink = {
  __typename?: 'MediaUploadLink';
  fields: MediaUploadLinkFields;
  url: Scalars['String'];
};

export type MediaUploadLinkFields = {
  __typename?: 'MediaUploadLinkFields';
  Policy: Scalars['String'];
  X_Amz_Algorithm: Scalars['String'];
  X_Amz_Credential: Scalars['String'];
  X_Amz_Date: Scalars['String'];
  X_Amz_Security_Token?: Maybe<Scalars['String']>;
  X_Amz_Signature: Scalars['String'];
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export { MeetRequirementEnum };

export type MergeApplication = {
  __typename?: 'MergeApplication';
  appliedAt?: Maybe<Scalars['DateTime']>;
  candidate: Candidate;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentMergeJobInterviewStageId?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeCandidate: MergeCandidate;
  mergeCandidateId?: Maybe<Scalars['String']>;
  mergeInterviews: Array<MergeInterview>;
  mergeJob: MergeJob;
  mergeJobId?: Maybe<Scalars['String']>;
  mergeRejectReason: MergeRejectReason;
  mergeRejectReasonId?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeCandidate = {
  __typename?: 'MergeCandidate';
  atsIntegration: AtsIntegration;
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastInteractionAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  mergeApplications: Array<MergeApplication>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  remoteUpdatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeDepartment = {
  __typename?: 'MergeDepartment';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MergeInterview = {
  __typename?: 'MergeInterview';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  mergeApplication: MergeApplication;
  mergeApplicationId: Scalars['String'];
  mergeJobInterviewStage: MergeJobInterviewStage;
  mergeJobInterviewStageId?: Maybe<Scalars['String']>;
  mergeScorecards?: Maybe<Array<MergeScorecard>>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  remoteUpdatedAt?: Maybe<Scalars['DateTime']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeJob = {
  __typename?: 'MergeJob';
  atsIntegration: AtsIntegration;
  confidential: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mergeDepartments: Array<MergeDepartment>;
  mergeHiringManagers: Array<MergeUser>;
  mergeOffices: Array<MergeOffice>;
  mergeRecruiters: Array<MergeUser>;
  name?: Maybe<Scalars['String']>;
  remoteCreatedAt: Scalars['DateTime'];
  remoteId: Scalars['String'];
  remoteUpdatedAt: Scalars['DateTime'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MergeJobInterviewStage = {
  __typename?: 'MergeJobInterviewStage';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeJobId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeOffice = {
  __typename?: 'MergeOffice';
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MergeRejectReason = {
  __typename?: 'MergeRejectReason';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeScorecard = {
  __typename?: 'MergeScorecard';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mergeApplicationId?: Maybe<Scalars['String']>;
  mergeInterview: MergeInterview;
  mergeInterviewId?: Maybe<Scalars['String']>;
  mergeInterviewerId?: Maybe<Scalars['String']>;
  overallRecommendation?: Maybe<Scalars['String']>;
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  remoteId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type MergeUser = {
  __typename?: 'MergeUser';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export { MessageDirectionType };

export type MostCommondRejectReasons = {
  __typename?: 'MostCommondRejectReasons';
  count: Scalars['Float'];
  description: Scalars['String'];
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCandidate: Candidate;
  acceptCandidateCollaboration: Candidate;
  acceptLead?: Maybe<AcceptedLead>;
  archiveSearchAgreement?: Maybe<Scalars['String']>;
  cancelCollaboration: Scalars['Boolean'];
  cancelSearchAgreement?: Maybe<Scalars['String']>;
  candidateAcceptOffer: Candidate;
  createCandidate: Candidate;
  createCandidateDocument: Candidate;
  createCollaboration: Scalars['Boolean'];
  createCompany?: Maybe<Company>;
  createEmployerInvitation?: Maybe<EmployerInvitationByRecruiter>;
  createEtchPacketAndGenerateEtchSignURL?: Maybe<Scalars['String']>;
  createOrUpdateRoleRequirements: Scalars['Boolean'];
  createPublicDossier: Scalars['String'];
  createRecruiter: Recruiter;
  createRoleQuestion: RoleQuestion;
  createRoleRequirement: RoleRequirement;
  createRoleRequirements: Array<RoleRequirement>;
  createRoleTitle?: Maybe<RoleTitle>;
  createSearchAgreement?: Maybe<SearchAgreement>;
  createSearchReview: Scalars['Boolean'];
  deleteCandidateDocument: Scalars['Boolean'];
  deleteRecruiterPlacement: Scalars['Boolean'];
  deleteRoleRequirement: Scalars['Boolean'];
  deleteRoleRequirements: Scalars['Boolean'];
  inviteTeamMember: Scalars['String'];
  loadFixtures: Scalars['Boolean'];
  rejectCandidate: Candidate;
  rejectLead?: Maybe<Lead>;
  rejectPublicCandidate: Candidate;
  removeTeamMember: Scalars['Boolean'];
  sendSearchAgreementReminder: Scalars['Boolean'];
  setUserNotificationSetting: Scalars['Boolean'];
  unarchiveSearchAgreement?: Maybe<Scalars['String']>;
  updateCandidate: Candidate;
  updateCandidateDocuments: Candidate;
  updateCandidateRoleRequirements: Scalars['Boolean'];
  updateMarketplaceRoleStatus?: Maybe<MarketplaceRoleRecruiter>;
  updateOnboardingStatusToInReview: Recruiter;
  updateOrCreateRecruiterPlacement?: Maybe<Recruiter>;
  updatePublicCandidate: Candidate;
  updateRecruiter?: Maybe<Recruiter>;
  updateRecruiterProfile?: Maybe<Recruiter>;
  updateRecruiterProfileBio?: Maybe<Recruiter>;
  updateRecruiterProfileEndorsements?: Maybe<Recruiter>;
  updateRecruiterTimeToPlacement?: Maybe<Recruiter>;
  updateRoleRequirement: RoleRequirement;
  updateRoleRequirements: Array<RoleRequirement>;
  updateSearchAgreement?: Maybe<SearchAgreement>;
  updateSearchAgreementAfterRecruiterSignature?: Maybe<Scalars['String']>;
  updateUser: User;
  updateUserTermsAndPolicy: User;
  voidSearchAgreement?: Maybe<Scalars['String']>;
};

export type MutationAcceptCandidateArgs = {
  input: RecruiterAcceptCandidateInput;
};

export type MutationAcceptCandidateCollaborationArgs = {
  input: RecruiterAcceptCandidateInput;
};

export type MutationAcceptLeadArgs = {
  id: Scalars['String'];
};

export type MutationArchiveSearchAgreementArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationCancelCollaborationArgs = {
  collaborationId: Scalars['String'];
  roleId: Scalars['String'];
};

export type MutationCancelSearchAgreementArgs = {
  input: RecruiterTerminatedSearchAgreementInput;
};

export type MutationCandidateAcceptOfferArgs = {
  input: CandidateAcceptOfferInput;
};

export type MutationCreateCandidateArgs = {
  input: CreateCandidateInput;
};

export type MutationCreateCandidateDocumentArgs = {
  input: CreateCandidateDocumentInput;
};

export type MutationCreateCollaborationArgs = {
  input: CollaborationInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateEmployerInvitationArgs = {
  input: EmployerInvitationByRecruiterInput;
};

export type MutationCreateEtchPacketAndGenerateEtchSignUrlArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationCreateOrUpdateRoleRequirementsArgs = {
  input: UpdateRoleRequirementsInput;
};

export type MutationCreatePublicDossierArgs = {
  roleId: Scalars['String'];
};

export type MutationCreateRecruiterArgs = {
  input: CreateRecruiterInput;
};

export type MutationCreateRoleQuestionArgs = {
  input: CreateRoleQuestionInput;
};

export type MutationCreateRoleRequirementArgs = {
  input: CreateRoleRequirementInput;
};

export type MutationCreateRoleRequirementsArgs = {
  inputs: Array<CreateRoleRequirementInput>;
};

export type MutationCreateRoleTitleArgs = {
  roleName: Scalars['String'];
};

export type MutationCreateSearchAgreementArgs = {
  searchAgreementInput: CreateSearchAgreementRecruiterInput;
};

export type MutationCreateSearchReviewArgs = {
  input: RecruiterSearchReviewInput;
  searchAgreementRoleId: Scalars['String'];
};

export type MutationDeleteCandidateDocumentArgs = {
  documentId: Scalars['String'];
};

export type MutationDeleteRecruiterPlacementArgs = {
  placementId: Scalars['String'];
};

export type MutationDeleteRoleRequirementArgs = {
  roleRequirementId: Scalars['String'];
};

export type MutationDeleteRoleRequirementsArgs = {
  roleIds: Array<Scalars['String']>;
};

export type MutationInviteTeamMemberArgs = {
  data: InviteTeamMemberInput;
};

export type MutationRejectCandidateArgs = {
  input: RejectCandidateInput;
};

export type MutationRejectLeadArgs = {
  id: Scalars['String'];
};

export type MutationRejectPublicCandidateArgs = {
  input: RejectCandidateInput;
};

export type MutationRemoveTeamMemberArgs = {
  userId: Scalars['String'];
};

export type MutationSendSearchAgreementReminderArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationSetUserNotificationSettingArgs = {
  input: Array<SetUserNotificationSettingInput>;
};

export type MutationUnarchiveSearchAgreementArgs = {
  searchAgreementId: Scalars['String'];
};

export type MutationUpdateCandidateArgs = {
  input: UpdateCandidateInput;
};

export type MutationUpdateCandidateDocumentsArgs = {
  input: UpdateCandidateDocumentsInput;
};

export type MutationUpdateCandidateRoleRequirementsArgs = {
  inputs: Array<UpdateCandidateRoleRequirementInput>;
};

export type MutationUpdateMarketplaceRoleStatusArgs = {
  marketplaceVisibility: MarketplaceVisibilityEnum;
  roleId: Scalars['String'];
  status: MarketplaceRoleRecruiterStatus;
};

export type MutationUpdateOrCreateRecruiterPlacementArgs = {
  placement: UpdateRecruiterProfilePlacementInput;
};

export type MutationUpdatePublicCandidateArgs = {
  publicDossierId: Scalars['String'];
};

export type MutationUpdateRecruiterArgs = {
  data: UpdateRecruiterInput;
};

export type MutationUpdateRecruiterProfileArgs = {
  data: UpdateRecruiterProfileInput;
};

export type MutationUpdateRecruiterProfileBioArgs = {
  bio: Scalars['String'];
  bioJSON: Scalars['String'];
};

export type MutationUpdateRecruiterProfileEndorsementsArgs = {
  endorsements: Array<UpdateRecruiterProfileEndorsementsInput>;
};

export type MutationUpdateRecruiterTimeToPlacementArgs = {
  timeToPlacement: Scalars['Float'];
};

export type MutationUpdateRoleRequirementArgs = {
  input: UpdateRoleRequirementInput;
};

export type MutationUpdateRoleRequirementsArgs = {
  inputs: Array<UpdateRoleRequirementInput>;
};

export type MutationUpdateSearchAgreementArgs = {
  searchAgreementInput: UpdateSearchAgreementInput;
};

export type MutationUpdateSearchAgreementAfterRecruiterSignatureArgs = {
  input: UpdateSearchAgreementAfterRecruiterSignatureInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserTermsAndPolicyArgs = {
  data: UpdateUserTermsAndPolicyInput;
};

export type MutationVoidSearchAgreementArgs = {
  input: RecruiterTerminatedSearchAgreementInput;
};

export type NumberCandidates = {
  __typename?: 'NumberCandidates';
  current: Scalars['Float'];
  previous: Scalars['Float'];
};

export { OnboardingStatus };

export type Options = {
  __typename?: 'Options';
  label: Scalars['String'];
  value: Scalars['String'];
};

export { OutreachChannelEnum };

export type OutreachMessage = {
  __typename?: 'OutreachMessage';
  createdAt: Scalars['DateTime'];
  frontEmailMessage?: Maybe<FrontEmailMessage>;
  id: Scalars['ID'];
  message: Scalars['String'];
  outreachSequence: OutreachSequence;
  outreachSequenceId: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  type: OutreachMessageType;
};

export { OutreachMessageType };

export type OutreachSequence = {
  __typename?: 'OutreachSequence';
  assignee?: Maybe<ProspectAssigneeEnum>;
  candidateResponse?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  frontEmailMessages: Array<FrontEmailMessage>;
  id: Scalars['ID'];
  isBackfill: Scalars['Boolean'];
  lastFrontEmailMessage?: Maybe<FrontEmailMessage>;
  lastMessageSent?: Maybe<OutreachMessage>;
  lastScheduleMessage?: Maybe<OutreachMessage>;
  messageToSend?: Maybe<OutreachMessage>;
  messagesStage?: Maybe<OutreachMessageType>;
  outreachChannel: OutreachChannelEnum;
  outreachMessages: Array<OutreachMessage>;
  prospect: Prospect;
  prospectId: Scalars['String'];
  scheduledAt?: Maybe<Scalars['DateTime']>;
  status: OutreachSequenceStatus;
};

export { OutreachSequenceStatus };

export type ParsedLinkedInProfile = {
  __typename?: 'ParsedLinkedInProfile';
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkedInProfile: LinkedInProfile;
  name: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  workExperience?: Maybe<Array<ParsedWorkExpirienceType>>;
};

export type ParsedWorkExpirienceType = {
  __typename?: 'ParsedWorkExpirienceType';
  company: Company;
  roleTitle: Scalars['String'];
};

export type Payout = {
  __typename?: 'Payout';
  agency: Agency;
  amount: Scalars['Float'];
  amountWithoutAdjustment: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  paidAt?: Maybe<Scalars['DateTime']>;
  payoutType: PayoutTypeEnum;
  searchAgreement?: Maybe<SearchAgreement>;
};

export type PayoutOutput = {
  __typename?: 'PayoutOutput';
  agency: Agency;
  amount: Scalars['Float'];
  amountWithoutAdjustment: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  employerName: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['ID'];
  paidAmount: Scalars['Float'];
  paidAt?: Maybe<Scalars['DateTime']>;
  payoutType: PayoutTypeEnum;
  recruiterName?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  searchAgreement?: Maybe<SearchAgreement>;
  status: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

export { PayoutTypeEnum };

export type Period = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type Placement = {
  __typename?: 'Placement';
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['String']>;
  company: Company;
  companyId: Scalars['String'];
  companyStage?: Maybe<CompanyStage>;
  companyStageId?: Maybe<Scalars['String']>;
  educationDegree: EducationDegree;
  educationDegreeId?: Maybe<Scalars['String']>;
  educationInstitution: EducationInstitution;
  educationInstitutionId?: Maybe<Scalars['String']>;
  educationMajor: EducationMajor;
  educationMajorId?: Maybe<Scalars['String']>;
  firstYearBaseSalary?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  linkedIn: Scalars['String'];
  placedAt: Scalars['DateTime'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  roleFunction: RoleFunction;
  roleFunctionId: Scalars['String'];
  roleSeniority: RoleSeniority;
  roleSeniorityId: Scalars['String'];
  roleTitle?: Maybe<RoleTitle>;
  roleTitleId?: Maybe<Scalars['String']>;
  tenureMonths?: Maybe<Scalars['Float']>;
  yoeMonth?: Maybe<Scalars['Float']>;
};

export type PlacementFeeOutput = {
  __typename?: 'PlacementFeeOutput';
  amount: Scalars['Float'];
  feeType: FeeScheduleTypeEnum;
};

export type PlacementGeographyGraphData = {
  __typename?: 'PlacementGeographyGraphData';
  cityCode: Scalars['ID'];
  cityName: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  region?: Maybe<Scalars['String']>;
  stateAbbrev: Scalars['String'];
  stateName: Scalars['String'];
  timezone?: Maybe<Timezone>;
  timezoneId?: Maybe<Scalars['String']>;
};

export type PlacementsByRangeDateOutput = {
  __typename?: 'PlacementsByRangeDateOutput';
  candidateCount: Scalars['Float'];
  showEmptyState: Scalars['Boolean'];
};

export type PotentialEarningOutput = {
  __typename?: 'PotentialEarningOutput';
  date: Scalars['String'];
  employerName: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['String'];
  lowerEarning?: Maybe<Scalars['Float']>;
  roleName?: Maybe<Scalars['String']>;
  searchAgreementId: Scalars['String'];
  upperEarning: Scalars['Float'];
};

export type PotentialSearchValueOutput = {
  __typename?: 'PotentialSearchValueOutput';
  maxPotentialSearchValue: Scalars['Float'];
  minPotentialSearchValue: Scalars['Float'];
  showEmptyState: Scalars['Boolean'];
};

export { PricingTypeEnum };

export type ProfileOnboardingStep = {
  __typename?: 'ProfileOnboardingStep';
  isCompleted: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Prospect = {
  __typename?: 'Prospect';
  averageRoleRequirementsScore: Scalars['Float'];
  candidate?: Maybe<Candidate>;
  candidateId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailEnrichmentStatus: EnrichmentProspectStatus;
  evaluationScore?: Maybe<EvaluationStatus>;
  id: Scalars['ID'];
  isQualified?: Maybe<Scalars['Boolean']>;
  linkedInProfile: LinkedInProfileRaw;
  manualDisqualifyOverride: Scalars['Boolean'];
  matchPercentage: Scalars['Float'];
  outreachSequence?: Maybe<OutreachSequence>;
  outreachSequenceExists: Scalars['Boolean'];
  phoneNumberEnrichmentStatus: EnrichmentProspectStatus;
  prospectPhoneNumbers?: Maybe<Array<ProspectPhoneNumber>>;
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  prospectSearch: ProspectSearch;
  prospectSearchId: Scalars['String'];
  publicIdentifier: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  roleMeetRequirementsCount: Scalars['Float'];
  roleRequirementsEvaluatedCount: Scalars['Float'];
  rrMustHaveFailingCount: Scalars['Float'];
  rrPassingPercent: Scalars['Float'];
  score: Scalars['Float'];
  source: Scalars['String'];
  status: ProspectStatus;
};

export { ProspectAssigneeEnum };

export type ProspectPhoneNumber = {
  __typename?: 'ProspectPhoneNumber';
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  prospect?: Maybe<Prospect>;
  prospectId?: Maybe<Scalars['String']>;
};

export type ProspectRoleRequirement = {
  __typename?: 'ProspectRoleRequirement';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  llmEvaluatorType: Scalars['String'];
  meetRequirement?: Maybe<Scalars['String']>;
  meetRequirementExplanation?: Maybe<Scalars['String']>;
  parentWorkflowId: Scalars['String'];
  prospect: Prospect;
  prospectId: Scalars['String'];
  roleRequirement?: Maybe<RoleRequirement>;
  roleRequirementId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workflowId?: Maybe<Scalars['String']>;
};

export type ProspectSearch = {
  __typename?: 'ProspectSearch';
  continuationScore?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastExpansionTime?: Maybe<Scalars['DateTime']>;
  lastKLimit?: Maybe<Scalars['Float']>;
  lastSearchDuplicateCount?: Maybe<Scalars['Float']>;
  lastSearchNewCount?: Maybe<Scalars['Float']>;
  linkedInPublicIdentifier?: Maybe<Scalars['String']>;
  parentProspectSearchId?: Maybe<Scalars['String']>;
  prospects: Array<Prospect>;
  prospectsInterestedCount: Scalars['Float'];
  qualifiedProspectPercentage: Scalars['Float'];
  qualifiedProspectsCount: Scalars['Float'];
  query?: Maybe<Scalars['String']>;
  role: Role;
  roleId: Scalars['String'];
  searchFilters: SearchFilters;
  searchType: ProspectSearchType;
  showQualifiedProspectsCreatedBefore?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export { ProspectSearchType };

export { ProspectStatus };

export type PublicDossier = {
  __typename?: 'PublicDossier';
  candidate?: Maybe<Candidate>;
  dossierURL: Scalars['String'];
  id: Scalars['ID'];
  prospect?: Maybe<Prospect>;
  role: Role;
  shortCode: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  acceptanceRateMetrics: AcceptanceRateMetricsOutput;
  agencyEarnings: Array<AgencyEarning>;
  agencyLastYearEarnings: Array<AgencyMonthlyEarning>;
  agencyTransactions?: Maybe<Array<PayoutOutput>>;
  allowRecruiterVerifiedInterest: Scalars['Boolean'];
  apiVersion: Scalars['String'];
  availableEmployers: Array<Employer>;
  availableRoles?: Maybe<Array<Role>>;
  availableRolesByEmployer?: Maybe<Array<Role>>;
  calculateTimeToPlacementByRecruiter: Scalars['Float'];
  candidateActivityHistories: CandidateActivityHistoryOutput;
  candidateById: CandidateDetailsOutput;
  candidateMarketplaceMetrics: CandidateMarketPlaceMetricsOutput;
  candidateMetrics: CandidateStatusMetricsOutput;
  candidates: CandidatesByAgency;
  candidatesForRole: CandidateWithMetrics;
  checkStatusOrGenerateAccountLinkURL?: Maybe<StripeStatus>;
  cities?: Maybe<Array<City>>;
  clientCompanyStages: Array<ClientCompanyStage>;
  companies: Array<Company>;
  countActiveRoles: Scalars['Int'];
  countSignedSearchAgreements: EarningsMetricsOutput;
  createMediaUploadLink: MediaUploadLink;
  currentRecruiter?: Maybe<CurrentRecruiter>;
  currentRecruiterTeam?: Maybe<Array<Recruiter>>;
  employeesByEmployer: Array<Employee>;
  employerInvitations: Array<EmployerInvitationByRecruiter>;
  endorsementsByRecruiter: Array<Endorsement>;
  getAnvilSignaturedFiles?: Maybe<DownloadDocumentsResultOutput>;
  getTimeZone?: Maybe<Array<Timezone>>;
  getUser?: Maybe<User>;
  hasArchivedSearchAgreements: Scalars['Boolean'];
  hasFirstInvoicePaid: Scalars['Boolean'];
  industries: Array<Industry>;
  invoicePdfUrl?: Maybe<Scalars['String']>;
  latestMarketplaceRoles: Array<MarketplaceRoleOutput>;
  leadById?: Maybe<LeadOutput>;
  leadsByRecruiterAgency: Array<DetailedLead>;
  listUserNotificationSetting: Array<UserNotificationSetting>;
  marketplaceRoles: Array<MarketplaceRoleWithStatus>;
  payoutsByAgency: Scalars['Float'];
  placementAverageTenure: Scalars['Float'];
  placementCompanies: Array<Company>;
  placementsByRangeDate: PlacementsByRangeDateOutput;
  placementsByRecruiter: Array<Placement>;
  potentialEarnings: Array<PotentialEarningOutput>;
  potentialSearchValue: PotentialSearchValueOutput;
  profileOnboardingSteps: Array<ProfileOnboardingStep>;
  publicDossier?: Maybe<PublicDossier>;
  recentActivity: Array<RecentActivityOutput>;
  recruiterById: Recruiter;
  recruiterCommonIndustries: Array<CommonIndustries>;
  recruiterCommonPlacedInstitutions: Array<CommonEducationInstitution>;
  recruiterFormOptions: RecruiterFormOptionsOutput;
  recruiterPlacementGeographies: Array<PlacementGeographyGraphData>;
  recruiterSearchAgreementById?: Maybe<SearchAgreement>;
  recruiterTopSeniority: Array<TopRoleSeniority>;
  recruiters: Array<Recruiter>;
  recruitersByAgency: Array<Recruiter>;
  rejectReasons: Array<CandidateActivityHistory>;
  reviewsByRecruiter: ReviewWithAverage;
  roleById?: Maybe<RecruiterRoleWithStatus>;
  roleCandidateSubmissionLimit?: Maybe<Scalars['Int']>;
  roleDossier: RoleDossierOutput;
  roleFunctions?: Maybe<Array<RoleFunction>>;
  roleFunctionsAndCities?: Maybe<RoleFunctionsAndCitiesOutput>;
  roleQuestions: Array<RoleQuestion>;
  roleRequirementTemplates: Array<RoleRequirementTemplate>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniorities?: Maybe<Array<RoleSeniority>>;
  roles: Array<RecruiterRoleWithStatus>;
  searchAgreementByRecruiterAgency?: Maybe<Array<SearchAgreement>>;
  searchArchivedAgreementByRecruiter?: Maybe<Array<SearchAgreement>>;
  sendReportRecruiterReviewEmail: Scalars['Boolean'];
  successfulPlacementsByRecruiter: Scalars['Float'];
  topClientsByPlacement: Array<RankingOutput>;
  topClientsByRevenue: Array<RankingOutput>;
  topRecruiterBySubmittedCandidate: TopRecruitersBySubmittedCandidateOutput;
  topRecruitersByRevenue: Array<RankingOutput>;
  topRoleFunctions: Array<TopRoleFunctions>;
  upcomingEarnings?: Maybe<Array<UpcomingEarningsOutput>>;
};

export type QueryAgencyEarningsArgs = {
  input: AgencyEarningsInput;
};

export type QueryAgencyLastYearEarningsArgs = {
  paymentStatus: Scalars['String'];
};

export type QueryAgencyTransactionsArgs = {
  rangeDate: RangeDateInput;
};

export type QueryAvailableRolesArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
};

export type QueryAvailableRolesByEmployerArgs = {
  employerId: Scalars['String'];
};

export type QueryCalculateTimeToPlacementByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCandidateActivityHistoriesArgs = {
  filter: CandidateActivityHistoryFilter;
  order: Scalars['String'];
};

export type QueryCandidateByIdArgs = {
  id: Scalars['String'];
};

export type QueryCandidateMarketplaceMetricsArgs = {
  id: Scalars['String'];
};

export type QueryCandidatesForRoleArgs = {
  id: Scalars['String'];
};

export type QueryClientCompanyStagesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryCountSignedSearchAgreementsArgs = {
  input: RangeDateInput;
};

export type QueryCreateMediaUploadLinkArgs = {
  data: CreateMediaUploadLinkInput;
};

export type QueryEmployeesByEmployerArgs = {
  employerId: Scalars['String'];
};

export type QueryEndorsementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryGetAnvilSignaturedFilesArgs = {
  searchAgreementId: Scalars['String'];
};

export type QueryInvoicePdfUrlArgs = {
  invoiceId: Scalars['String'];
};

export type QueryLeadByIdArgs = {
  id: Scalars['String'];
};

export type QueryPayoutsByAgencyArgs = {
  input: RangeDateInput;
};

export type QueryPlacementAverageTenureArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPlacementCompaniesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPlacementsByRangeDateArgs = {
  input: RangeDateInput;
};

export type QueryPlacementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryPublicDossierArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterByIdArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterCommonIndustriesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterCommonPlacedInstitutionsArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterPlacementGeographiesArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRecruiterSearchAgreementByIdArgs = {
  id: Scalars['String'];
};

export type QueryRecruiterTopSeniorityArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRejectReasonsArgs = {
  roleId: Scalars['String'];
};

export type QueryReviewsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryRoleByIdArgs = {
  id: Scalars['String'];
};

export type QueryRoleCandidateSubmissionLimitArgs = {
  roleId: Scalars['String'];
};

export type QueryRoleDossierArgs = {
  roleId: Scalars['String'];
};

export type QueryRoleQuestionsArgs = {
  filter: RoleQuestionFilter;
};

export type QueryRoleRequirementTemplatesArgs = {
  filter: RoleRequirementTemplateFilter;
};

export type QueryRoleRequirementsArgs = {
  filter: RoleRequirementFilter;
};

export type QuerySearchAgreementByRecruiterAgencyArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchArchivedAgreementByRecruiterArgs = {
  maxCount?: InputMaybe<Scalars['Int']>;
};

export type QuerySendReportRecruiterReviewEmailArgs = {
  input: SendReportRecruiterReviewInput;
};

export type QuerySuccessfulPlacementsByRecruiterArgs = {
  recruiterId: Scalars['String'];
};

export type QueryTopClientsByPlacementArgs = {
  rangeDate: RangeDateInput;
};

export type QueryTopClientsByRevenueArgs = {
  rangeDate: RangeDateInput;
};

export type QueryTopRecruiterBySubmittedCandidateArgs = {
  input: RangeDateInput;
};

export type QueryTopRecruitersByRevenueArgs = {
  rangeDate: RangeDateInput;
};

export type QueryTopRoleFunctionsArgs = {
  recruiterId: Scalars['String'];
};

export type RangeDateInput = {
  currentPeriod: Period;
  previousPeriod?: InputMaybe<Period>;
};

export type RankingOutput = {
  __typename?: 'RankingOutput';
  hasData?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RecentActivityOutput = {
  __typename?: 'RecentActivityOutput';
  actualSalaryRange?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<Scalars['String']>;
  candidateActivity?: Maybe<CandidateActivityHistoryActivityType>;
  candidateId?: Maybe<Scalars['String']>;
  candidateName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  employerName: Scalars['String'];
  id: Scalars['String'];
  oldSalaryRange?: Maybe<Scalars['String']>;
  pausedBy?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  rolePrice?: Maybe<PlacementFeeOutput>;
  roleType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type RecommendedRecruiter = {
  __typename?: 'RecommendedRecruiter';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isInvitedToMarketplaceRole: Scalars['Boolean'];
  isPrimaryAgency: Scalars['Boolean'];
  isRequested: Scalars['Boolean'];
  isRequestedToDirect: Scalars['Boolean'];
  recruiter: Recruiter;
  recruiterId: Scalars['String'];
  role: Role;
  roleId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Recruiter = {
  __typename?: 'Recruiter';
  acceptanceRatePercent: Scalars['Float'];
  agency: Agency;
  agencyId: Scalars['String'];
  allowRecruiterVerifiedInterestOverride: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  bioJSON?: Maybe<Scalars['String']>;
  canceledSearchAgreements: Array<SearchAgreement>;
  candidates: Array<Candidate>;
  cities: Array<City>;
  createdAt: Scalars['DateTime'];
  employerInvitationsByRecruiter: Array<EmployerInvitationByRecruiter>;
  endorsements: Array<Endorsement>;
  id: Scalars['ID'];
  isMarketplaceRecruiter: Scalars['Boolean'];
  linkedIn?: Maybe<Scalars['String']>;
  location?: Maybe<City>;
  manualTimeToPlacement: Scalars['Float'];
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  maxPricePercent?: Maybe<Scalars['Int']>;
  minPricePercent?: Maybe<Scalars['Int']>;
  onboardingStatus: OnboardingStatus;
  placedCandidatesCount: Scalars['Float'];
  placements: Array<Placement>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  requestedLeads: Array<Lead>;
  roleFunctions: Array<RoleFunction>;
  schedulingLink15Min?: Maybe<Scalars['String']>;
  schedulingLink30Min?: Maybe<Scalars['String']>;
  schedulingLink60Min?: Maybe<Scalars['String']>;
  searchPreference?: Maybe<SearchPreferenceEnum>;
  searchReviews: Array<SearchAgreementReview>;
  showProfile: Scalars['Boolean'];
  signedSearchAgreements: Array<SearchAgreement>;
  timezone?: Maybe<Timezone>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type RecruiterAcceptCandidateInput = {
  candidateId: Scalars['String'];
};

export type RecruiterFormOptionsOutput = {
  __typename?: 'RecruiterFormOptionsOutput';
  cityOptions: Array<Options>;
  companyStageOptions: Array<Options>;
  industryOptions: Array<Options>;
  roleFunctionOptions: Array<Options>;
  roleSeniorityOptions: Array<Options>;
  roleTitleOptions: Array<Options>;
};

export type RecruiterInfo = {
  __typename?: 'RecruiterInfo';
  name: Scalars['String'];
  number: Scalars['Float'];
};

export type RecruiterRoleWithStatus = {
  __typename?: 'RecruiterRoleWithStatus';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  agencyName?: Maybe<Scalars['String']>;
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateCount: Scalars['Float'];
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  closedSearchProduct?: Maybe<ClosedSearchProduct>;
  collaboration?: Maybe<Collaboration>;
  collaborationAgencyName?: Maybe<Scalars['String']>;
  collaborationRecruiterName?: Maybe<Scalars['String']>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hasReview: Scalars['Boolean'];
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isCollaborationRecruiter?: Maybe<Scalars['Boolean']>;
  isDossierShareable: Scalars['Boolean'];
  isPrimaryAgency?: Maybe<Scalars['Boolean']>;
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mostCommonRejectReasons?: Maybe<Array<MostCommondRejectReasons>>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  placementFee?: Maybe<Scalars['Float']>;
  placementFeeType?: Maybe<Scalars['String']>;
  primarySearchAgreement?: Maybe<SearchAgreement>;
  primarySearchAgreementEndAt?: Maybe<Scalars['DateTime']>;
  primarySearchAgreementFeeTriggerType?: Maybe<Scalars['String']>;
  primarySearchAgreementId?: Maybe<Scalars['String']>;
  primarySearchAgreementMarketplaceRolePrice?: Maybe<PlacementFeeOutput>;
  primarySearchAgreementRoleId?: Maybe<Scalars['String']>;
  primarySearchAgreementStatus: Scalars['String'];
  primarySearchAgreementVisibility: MarketplaceVisibilityEnum;
  prospectSearches: Array<ProspectSearch>;
  publicDossierUrl?: Maybe<Scalars['String']>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  recruiterName: Scalars['String'];
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  showRequirementsBillboard?: Maybe<Scalars['Boolean']>;
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export { RecruiterSearchAgreementActivityCategory };

export type RecruiterSearchReviewInput = {
  communication: Scalars['Float'];
  employerId: Scalars['String'];
  overallRating: Scalars['Float'];
  responsiveness: Scalars['Float'];
  review: Scalars['String'];
  timeliness: Scalars['Float'];
};

export type RecruiterTerminatedSearchAgreementInput = {
  reasonCategory: RecruiterSearchAgreementActivityCategory;
  reasonDetails: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type RejectCandidateInput = {
  candidateId: Scalars['String'];
  rejectFeedback?: InputMaybe<Scalars['String']>;
  rejectReason?: InputMaybe<CandidateRejectedReasonEnum>;
  shareRejectionInfo?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<CandidateActivityHistoryActivityType>;
};

export { RemoteWorkPolicyEnum };

export type ReviewAverages = {
  __typename?: 'ReviewAverages';
  candidateSubmissionQualityAverage: Scalars['Float'];
  comunicationAverage: Scalars['Float'];
  overallRatingAverage: Scalars['Float'];
  timeToPlacementAverage: Scalars['Float'];
  timelessAverage: Scalars['Float'];
};

export type ReviewWithAverage = {
  __typename?: 'ReviewWithAverage';
  reviewAverages: ReviewAverages;
  searchReviews: Array<SearchAgreementReview>;
};

export type Role = {
  __typename?: 'Role';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  prospectSearches: Array<ProspectSearch>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type RoleAgencyAllowList = {
  __typename?: 'RoleAgencyAllowList';
  agency: Agency;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
};

export type RoleCollaborator = {
  __typename?: 'RoleCollaborator';
  collaboratorType: RoleCollaboratorTypeEnum;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
  roleId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export { RoleCollaboratorTypeEnum };

export type RoleDossierOutput = {
  __typename?: 'RoleDossierOutput';
  IsRoleAIActive: Scalars['Boolean'];
  acceptanceRateVisibilityThreshold: Scalars['Float'];
  allDirectSearchAgreements: Array<SearchAgreement>;
  allSearchAgreements: Array<SearchAgreement>;
  allowedAgencies: Array<RoleAgencyAllowList>;
  candidateNote?: Maybe<Scalars['String']>;
  cities: Array<City>;
  collaborators: Array<RoleCollaborator>;
  continuationScoreThreshold?: Maybe<Scalars['Float']>;
  countActiveRecruiters: Scalars['Float'];
  countActiveSearchAgreements: Scalars['Float'];
  countCandidates: Scalars['Float'];
  countCandidatesInProcess: Scalars['Float'];
  countInterestedCandidates: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionJSON?: Maybe<Scalars['String']>;
  directSearchAgreement?: Maybe<SearchAgreement>;
  employer: Employer;
  employerId: Scalars['String'];
  enableKnnBackgroundWorkflow: Scalars['Boolean'];
  enableOutreachBackgroundWorkflow: Scalars['Boolean'];
  expectedFirstYearSalaryMax?: Maybe<Scalars['Float']>;
  expectedFirstYearSalaryMin?: Maybe<Scalars['Float']>;
  expectedFirstYearShareCount?: Maybe<Scalars['Int']>;
  expectedSalaryUpdatedAt?: Maybe<Scalars['DateTime']>;
  feeGuidance?: Maybe<Scalars['String']>;
  hiringManager?: Maybe<Employee>;
  id: Scalars['ID'];
  idealCandidateDescription?: Maybe<Scalars['String']>;
  idealCandidateProfiles: Array<IdealCandidateProfile>;
  idealCandidateSearchFilters: SearchFilters;
  idealCandidateSearchQuery?: Maybe<Scalars['String']>;
  interviewer?: Maybe<Employee>;
  interviewerId?: Maybe<Scalars['String']>;
  inviteSearchAgreement?: Maybe<SearchAgreement>;
  isArchived: Scalars['Boolean'];
  isDossierShareable: Scalars['Boolean'];
  isRoleAlreadyLunched: Scalars['Boolean'];
  jobDescriptionLink?: Maybe<Scalars['String']>;
  knnProspectLimit: Scalars['Int'];
  leads: Array<Lead>;
  marketplaceRoleRecruiters: Array<MarketplaceRoleRecruiter>;
  marketplaceSearchAgreement?: Maybe<SearchAgreement>;
  marketplaceStatusForEmployer: Scalars['String'];
  marketplaceVisibility?: Maybe<Scalars['String']>;
  mergeJob?: Maybe<MergeJob>;
  mustHaveRoleRequirementsLimitOverride?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  noteJSON?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  oldExpectedSalaryRange?: Maybe<Scalars['String']>;
  outreachSequenceThreshold: Scalars['Int'];
  pauseEmployerReviewExpirationsUntilTimestamp?: Maybe<Scalars['DateTime']>;
  pausedExpiration: Scalars['Boolean'];
  placementFee?: Maybe<PlacementFeeOutput>;
  prospectSearches: Array<ProspectSearch>;
  publicDossierUrl?: Maybe<Scalars['String']>;
  publicDossiers: Array<PublicDossier>;
  publicSearchAgreement?: Maybe<SearchAgreement>;
  quantity?: Maybe<Scalars['Int']>;
  recommendedRecruiters: Array<RecommendedRecruiter>;
  remoteWorkPolicy: RemoteWorkPolicyEnum;
  requestedByEmployee: Employee;
  requestedByEmployeeId?: Maybe<Scalars['String']>;
  roleFunction?: Maybe<RoleFunction>;
  roleRequirements: Array<RoleRequirement>;
  roleSeniority?: Maybe<RoleSeniority>;
  roleTitle?: Maybe<RoleTitle>;
  roleType: RoleTypeEnum;
  scheduleLink?: Maybe<Scalars['String']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  searchStatus?: Maybe<Scalars['String']>;
  showProspectsAndMetrics: Scalars['Boolean'];
  skipScheduleConfirmation: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RoleFunction = {
  __typename?: 'RoleFunction';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  roles: Array<Role>;
};

export type RoleFunctionsAndCitiesOutput = {
  __typename?: 'RoleFunctionsAndCitiesOutput';
  cities: Array<City>;
  roleFunctions: Array<RoleFunction>;
};

export type RoleQuestion = {
  __typename?: 'RoleQuestion';
  answer?: Maybe<Scalars['String']>;
  answerer?: Maybe<Employee>;
  answererId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  question: Scalars['String'];
  status: RoleQuestionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleQuestionFilter = {
  roleId: Scalars['String'];
};

export { RoleQuestionStatus };

export type RoleRequirement = {
  __typename?: 'RoleRequirement';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  forceCandidateReview: Scalars['Boolean'];
  id: Scalars['ID'];
  isCritical: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  mustHave: Scalars['Boolean'];
  prompt: Scalars['String'];
  prospectRoleRequirements: Array<ProspectRoleRequirement>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleRequirementFilter = {
  roleId: Scalars['String'];
};

export type RoleRequirementInput = {
  id: Scalars['String'];
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
};

export type RoleRequirementTemplate = {
  __typename?: 'RoleRequirementTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  template: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RoleRequirementTemplateFilter = {
  roleFunctionId: Scalars['String'];
};

export type RoleSeniority = {
  __typename?: 'RoleSeniority';
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  placements: Array<Placement>;
  roles: Array<Role>;
};

export type RoleTitle = {
  __typename?: 'RoleTitle';
  createdByRecruiter?: Maybe<Recruiter>;
  createdByRecruiterId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  needsReview: Scalars['Boolean'];
  placements: Array<Placement>;
  roles: Array<Role>;
};

export { RoleTypeEnum };

export type SearchAgreement = {
  __typename?: 'SearchAgreement';
  addendums: Array<SearchAgreementAddendum>;
  agency?: Maybe<Agency>;
  anvilDocumentGroupEid?: Maybe<Scalars['String']>;
  archivedStatus: Scalars['String'];
  beginAt?: Maybe<Scalars['DateTime']>;
  canceledByEmployee?: Maybe<Employee>;
  canceledByRecruiter?: Maybe<Recruiter>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByEmployerId?: Maybe<Scalars['String']>;
  deletedByRecruiterId?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  employeeCanceledAt?: Maybe<Scalars['DateTime']>;
  employeeSignedAt?: Maybe<Scalars['DateTime']>;
  employeeVoidedAt?: Maybe<Scalars['DateTime']>;
  employer: Employer;
  endAt?: Maybe<Scalars['DateTime']>;
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates?: Maybe<Scalars['String']>;
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<SearchAgreementFeeSchedule>;
  feeType: Scalars['String'];
  fixedMarketplacePercentFee?: Maybe<Scalars['Float']>;
  fulfilledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  isPaused: Scalars['Boolean'];
  isProspect: Scalars['Boolean'];
  isSignedOffPlatform: Scalars['Boolean'];
  marketplacePercentFee: Scalars['Float'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  payouts: Array<Payout>;
  placementFeeSchedule?: Maybe<SearchAgreementFeeSchedule>;
  previousPriceChangeSearchAgreementId?: Maybe<Scalars['String']>;
  pricingType: PricingTypeEnum;
  recruiterCanceledAt?: Maybe<Scalars['DateTime']>;
  recruiterSignedAt?: Maybe<Scalars['DateTime']>;
  recruiterVoidedAt?: Maybe<Scalars['DateTime']>;
  replacementSearchNotificationWindowDays?: Maybe<Scalars['Int']>;
  replacementSearchTerminateDays?: Maybe<Scalars['Int']>;
  replacementSearchWindowMonths?: Maybe<Scalars['Int']>;
  searchAgreementRoles: Array<SearchAgreementRole>;
  signedByEmployee?: Maybe<Employee>;
  signedByEmployeeId?: Maybe<Scalars['String']>;
  signedByRecruiter?: Maybe<Recruiter>;
  signedByRecruiterId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  voidByEmployee: Employee;
  voidByRecruiter: Recruiter;
  whenFirstInvoice: Scalars['String'];
};

export type SearchAgreementAddendum = {
  __typename?: 'SearchAgreementAddendum';
  description: Scalars['String'];
  id: Scalars['ID'];
  searchAgreement: SearchAgreement;
  searchAgreementId: Scalars['String'];
};

export type SearchAgreementFeeSchedule = {
  __typename?: 'SearchAgreementFeeSchedule';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  feeAdjustmentType?: Maybe<FeeAdjustmentTypeEnum>;
  feeAmount: Scalars['Float'];
  feePaymentTermNetDays: Scalars['Int'];
  feeScheduleType: FeeScheduleTypeEnum;
  feeTriggerLimit?: Maybe<Scalars['Int']>;
  feeTriggerType: FeeTriggerTypeEnum;
  id: Scalars['ID'];
  oneTimeFeeRelativeSigningDays?: Maybe<Scalars['Int']>;
  oneTimeFeeTriggerAt?: Maybe<Scalars['DateTime']>;
  performanceCandidateAcceptQuantity?: Maybe<Scalars['Int']>;
  searchAgreement: SearchAgreement;
  updatedAt: Scalars['DateTime'];
};

export type SearchAgreementReview = {
  __typename?: 'SearchAgreementReview';
  candidateSubmissionQuality?: Maybe<Scalars['Float']>;
  communication: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  employer?: Maybe<Employer>;
  id: Scalars['ID'];
  overallRating: Scalars['Float'];
  recruiter?: Maybe<Recruiter>;
  recruiterId?: Maybe<Scalars['String']>;
  responsiveness?: Maybe<Scalars['Float']>;
  review?: Maybe<Scalars['String']>;
  reviewedByEmployee?: Maybe<Employee>;
  reviewedByEmployeeId?: Maybe<Scalars['String']>;
  reviewedByRecruiter?: Maybe<Recruiter>;
  reviewedByRecruiterId?: Maybe<Scalars['String']>;
  searchAgreementRole: SearchAgreementRole;
  timeToPlacement?: Maybe<Scalars['Float']>;
  timeliness: Scalars['Float'];
};

export type SearchAgreementRole = {
  __typename?: 'SearchAgreementRole';
  candidates: Array<Candidate>;
  collaborations: Array<Collaboration>;
  countActiveRecruiters: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role: Role;
  roleId: Scalars['String'];
  searchAgreement: SearchAgreement;
  searchAgreementId: Scalars['String'];
  searchReviews: Array<SearchAgreementReview>;
  updatedAt: Scalars['DateTime'];
};

export type SearchAgreementRoleInput = {
  added?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  role: CreateRoleInput;
};

export type SearchFilters = {
  __typename?: 'SearchFilters';
  must: Array<Scalars['JSON']>;
  must_not: Array<Scalars['JSON']>;
  should: Array<Scalars['JSON']>;
};

export type SearchInfo = {
  __typename?: 'SearchInfo';
  feeType?: Maybe<FeeScheduleTypeEnum>;
  id: Scalars['String'];
  marketplaceVisibility: MarketplaceVisibilityEnum;
  placementFee?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export { SearchPreferenceEnum };

export type SendReportRecruiterReviewInput = {
  employerName: Scalars['String'];
  reason: Scalars['String'];
  recruiterId: Scalars['String'];
  recruiterName: Scalars['String'];
  review: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewId: Scalars['String'];
};

export type SetUserNotificationSettingInput = {
  channel: UserNotificationChannel;
  enabled: Scalars['Boolean'];
  type: UserNotificationEnum;
};

export type StripeStatus = {
  __typename?: 'StripeStatus';
  check: Scalars['Boolean'];
  url: Scalars['String'];
};

export type Talent = {
  __typename?: 'Talent';
  agency?: Maybe<Agency>;
  candidates: Array<Candidate>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  linkedInProfile?: Maybe<LinkedInProfile>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Timezone = {
  __typename?: 'Timezone';
  cities: Array<City>;
  id: Scalars['ID'];
  recruiters: Array<Recruiter>;
  timezoneAbbrev: Scalars['String'];
  timezoneName: Scalars['String'];
  timezoneOffset: Scalars['String'];
};

export type TopRecruitersBySubmittedCandidateOutput = {
  __typename?: 'TopRecruitersBySubmittedCandidateOutput';
  candidates?: Maybe<NumberCandidates>;
  current: Scalars['Float'];
  hasData: Scalars['Boolean'];
  previous?: Maybe<Scalars['Float']>;
  recruiters?: Maybe<Array<RecruiterInfo>>;
};

export type TopRoleFunctions = {
  __typename?: 'TopRoleFunctions';
  id: Scalars['ID'];
  name: Scalars['String'];
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  roles: Array<Role>;
};

export type TopRoleSeniority = {
  __typename?: 'TopRoleSeniority';
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  placements: Array<Placement>;
  placementsCount: Scalars['Float'];
  roles: Array<Role>;
};

export type UpcomingEarningsOutput = {
  __typename?: 'UpcomingEarningsOutput';
  amount: Scalars['Float'];
  date: Scalars['String'];
  employerName: Scalars['String'];
  feeType: Scalars['String'];
  id: Scalars['String'];
  recruiterName: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type UpdateCandidateDocumentsInput = {
  candidateId?: InputMaybe<Scalars['String']>;
  documents: Array<Document>;
};

export type UpdateCandidateInput = {
  baseSalary?: InputMaybe<Scalars['Float']>;
  candidateId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  justification?: InputMaybe<Scalars['String']>;
  justificationJSON?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shareCount?: InputMaybe<Scalars['Float']>;
  skipScheduleConfirmation?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type UpdateCandidateRoleRequirementInput = {
  id: Scalars['String'];
  meetRequirement: MeetRequirementEnum;
};

export type UpdateRecruiterInput = {
  agencyName?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  bioJSON?: InputMaybe<Scalars['String']>;
  cities?: InputMaybe<Array<Scalars['String']>>;
  linkedIn?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  maxPricePercent?: InputMaybe<Scalars['Int']>;
  minPricePercent?: InputMaybe<Scalars['Int']>;
  roleFunctions?: InputMaybe<Array<Scalars['String']>>;
  schedulingLink15Min?: InputMaybe<Scalars['String']>;
  schedulingLink30Min?: InputMaybe<Scalars['String']>;
  schedulingLink60Min?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRecruiterProfileEndorsementsInput = {
  endorsement: Scalars['String'];
  endorserCompany: Scalars['String'];
  endorserTitle: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Float'];
};

export type UpdateRecruiterProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  bioJSON?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedIn: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  maxPricePercent?: InputMaybe<Scalars['Int']>;
  minPricePercent?: InputMaybe<Scalars['Int']>;
  photo?: InputMaybe<Scalars['String']>;
  roleFunctions?: InputMaybe<Array<Scalars['String']>>;
  searchPreference?: InputMaybe<SearchPreferenceEnum>;
  timeToPlacement?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRecruiterProfilePlacementInput = {
  cityId: Scalars['String'];
  companyId: Scalars['String'];
  companyStageId: Scalars['String'];
  firstYearBaseSalary?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  linkedIn: Scalars['String'];
  placedAt: Scalars['DateTime'];
  roleFunctionId: Scalars['String'];
  roleSeniorityId: Scalars['String'];
  roleTitleId: Scalars['String'];
};

export type UpdateRoleRequirementInput = {
  id: Scalars['String'];
  mustHave?: InputMaybe<Scalars['Boolean']>;
  prompt: Scalars['String'];
  roleId: Scalars['String'];
};

export type UpdateRoleRequirementsInput = {
  deletedRequirementIds: Array<Scalars['String']>;
  roleId: Scalars['String'];
  roleRequirements: Array<RoleRequirementInput>;
};

export type UpdateSearchAgreementAfterRecruiterSignatureInput = {
  anvilDocumentGroupEid: Scalars['String'];
  anvilEmployeeSignerEid: Scalars['String'];
  anvilEtchPacketEid: Scalars['String'];
  anvilRecruiterSignerEid: Scalars['String'];
  searchAgreementId: Scalars['String'];
};

export type UpdateSearchAgreementInput = {
  beginAt: Scalars['DateTime'];
  employerId: Scalars['String'];
  exclusive: Scalars['Boolean'];
  exclusivityCarveOutCandidates: Scalars['String'];
  expectedPlacements: ExpectedPlacementsEnum;
  feeSchedules: Array<FeeScheduleInput>;
  id: Scalars['String'];
  includeReplacementSearch: Scalars['Boolean'];
  isExclusivityCarveOutCandidatesSourcedByEmployer: Scalars['Boolean'];
  isExclusivityCarveOutExplicitListCandidates: Scalars['Boolean'];
  replacementSearchNotificationWindowDays?: InputMaybe<Scalars['Int']>;
  replacementSearchTerminateDays?: InputMaybe<Scalars['Int']>;
  replacementSearchWindowMonths?: InputMaybe<Scalars['Int']>;
  roles: Array<SearchAgreementRoleInput>;
  signedByEmployeeId: Scalars['String'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
};

export type UpdateUserTermsAndPolicyInput = {
  entity?: InputMaybe<EntityTypeEnum>;
  tou: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  employee?: Maybe<Employee>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isHeadRaceAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  notificationSettings: Array<UserNotificationSetting>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoUrl: Scalars['String'];
  recruiter?: Maybe<Recruiter>;
  slackUserId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userConsents: Array<UserConsent>;
};

export type UserConsent = {
  __typename?: 'UserConsent';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: User;
  userAgent: Scalars['String'];
  userConsentType: Scalars['String'];
  userId: Scalars['String'];
  versionNumber: Scalars['String'];
};

export { UserNotificationChannel };

export { UserNotificationEnum };

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  channel: UserNotificationChannel;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  type: UserNotificationEnum;
  user: User;
};

export type ApiVersionQueryVariables = Exact<{ [key: string]: never }>;

export type ApiVersionQuery = { __typename?: 'Query'; apiVersion: string };

export type ClientCompanyStagesQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type ClientCompanyStagesQuery = {
  __typename?: 'Query';
  clientCompanyStages: Array<{
    __typename?: 'ClientCompanyStage';
    name: string;
    count: number;
  }>;
};

export type EndorsementsByRecruiterQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type EndorsementsByRecruiterQuery = {
  __typename?: 'Query';
  endorsementsByRecruiter: Array<{
    __typename?: 'Endorsement';
    id: string;
    endorserTitle: string;
    endorserCompany: string;
    order: number;
    endorsement: string;
  }>;
};

export type IndustriesQueryVariables = Exact<{ [key: string]: never }>;

export type IndustriesQuery = {
  __typename?: 'Query';
  industries: Array<{ __typename?: 'Industry'; id: string; name: string }>;
};

export type InviteTeamMemberMutationVariables = Exact<{
  data: InviteTeamMemberInput;
}>;

export type InviteTeamMemberMutation = {
  __typename?: 'Mutation';
  inviteTeamMember: string;
};

export type ListUserNotificationSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListUserNotificationSettingQuery = {
  __typename?: 'Query';
  listUserNotificationSetting: Array<{
    __typename?: 'UserNotificationSetting';
    id: string;
    type: UserNotificationEnum;
    channel: UserNotificationChannel;
    enabled: boolean;
  }>;
};

export type PlacementCompaniesQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type PlacementCompaniesQuery = {
  __typename?: 'Query';
  placementCompanies: Array<{
    __typename?: 'Company';
    id: string;
    name: string;
    logoUrl: string;
  }>;
};

export type PlacementsByRecruiterQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type PlacementsByRecruiterQuery = {
  __typename?: 'Query';
  placementsByRecruiter: Array<{
    __typename?: 'Placement';
    id: string;
    firstYearBaseSalary?: number | null;
    tenureMonths?: number | null;
    yoeMonth?: number | null;
    candidateId?: string | null;
    companyStageId?: string | null;
    linkedIn: string;
    placedAt: Date;
    companyStage?: {
      __typename?: 'CompanyStage';
      id: string;
      name: string;
    } | null;
    city?: {
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    } | null;
    company: {
      __typename?: 'Company';
      id: string;
      name: string;
      logoUrl: string;
      industry?: { __typename?: 'Industry'; name: string } | null;
    };
    roleSeniority: { __typename?: 'RoleSeniority'; id: string; name: string };
    roleTitle?: { __typename?: 'RoleTitle'; id: string; name: string } | null;
    roleFunction: { __typename?: 'RoleFunction'; id: string; name: string };
  }>;
};

export type ProfileOnboardingStepsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProfileOnboardingStepsQuery = {
  __typename?: 'Query';
  profileOnboardingSteps: Array<{
    __typename?: 'ProfileOnboardingStep';
    name: string;
    isCompleted: boolean;
  }>;
};

export type RecruiterByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type RecruiterByIdQuery = {
  __typename?: 'Query';
  recruiterById: {
    __typename?: 'Recruiter';
    id: string;
    bio?: string | null;
    bioJSON?: string | null;
    linkedIn?: string | null;
    title?: string | null;
    manualTimeToPlacement: number;
    searchPreference?: SearchPreferenceEnum | null;
    maxPricePercent?: number | null;
    minPricePercent?: number | null;
    schedulingLink15Min?: string | null;
    schedulingLink30Min?: string | null;
    schedulingLink60Min?: string | null;
    location?: {
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    } | null;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      name: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      photoUrl: string;
      photo?: string | null;
    };
    agency: {
      __typename?: 'Agency';
      name?: string | null;
      website?: string | null;
    };
    timezone?: {
      __typename?: 'Timezone';
      timezoneName: string;
      timezoneAbbrev: string;
      timezoneOffset: string;
    } | null;
    cities: Array<{ __typename?: 'City'; id: string; cityName: string }>;
    roleFunctions: Array<{
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    }>;
  };
};

export type RecruiterCommonIndustriesQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterCommonIndustriesQuery = {
  __typename?: 'Query';
  recruiterCommonIndustries: Array<{
    __typename?: 'CommonIndustries';
    id: string;
    name: string;
    placementsCount: number;
  }>;
};

export type RecruiterTopSeniorityQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterTopSeniorityQuery = {
  __typename?: 'Query';
  recruiterTopSeniority: Array<{
    __typename?: 'TopRoleSeniority';
    id: string;
    name: string;
    placementsCount: number;
  }>;
};

export type RecruiterCommonPlacedInstitutionsQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterCommonPlacedInstitutionsQuery = {
  __typename?: 'Query';
  recruiterCommonPlacedInstitutions: Array<{
    __typename?: 'CommonEducationInstitution';
    id: string;
    name: string;
    placementsCount: number;
  }>;
};

export type RecruiterPlacementAverageTenureQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterPlacementAverageTenureQuery = {
  __typename?: 'Query';
  placementAverageTenure: number;
};

export type RecruiterPlacementGeographiesQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type RecruiterPlacementGeographiesQuery = {
  __typename?: 'Query';
  recruiterPlacementGeographies: Array<{
    __typename?: 'PlacementGeographyGraphData';
    cityCode: string;
    cityName: string;
    placementsCount: number;
    longitude: number;
    latitude: number;
  }>;
};

export type RemoveTeamMemberMutationVariables = Exact<{
  userId: Scalars['String'];
}>;

export type RemoveTeamMemberMutation = {
  __typename?: 'Mutation';
  removeTeamMember: boolean;
};

export type SendReportRecruiterReviewEmailQueryVariables = Exact<{
  input: SendReportRecruiterReviewInput;
}>;

export type SendReportRecruiterReviewEmailQuery = {
  __typename?: 'Query';
  sendReportRecruiterReviewEmail: boolean;
};

export type ReviewsByRecruiterQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type ReviewsByRecruiterQuery = {
  __typename?: 'Query';
  reviewsByRecruiter: {
    __typename?: 'ReviewWithAverage';
    searchReviews: Array<{
      __typename?: 'SearchAgreementReview';
      id: string;
      createdAt: Date;
      communication: number;
      timeliness: number;
      overallRating: number;
      candidateSubmissionQuality?: number | null;
      timeToPlacement?: number | null;
      review?: string | null;
      recruiterId?: string | null;
      searchAgreementRole: {
        __typename?: 'SearchAgreementRole';
        role: { __typename?: 'Role'; name: string };
      };
      reviewedByEmployee?: {
        __typename?: 'Employee';
        employer: {
          __typename?: 'Employer';
          company: { __typename?: 'Company'; name: string };
        };
      } | null;
    }>;
    reviewAverages: {
      __typename?: 'ReviewAverages';
      comunicationAverage: number;
      timelessAverage: number;
      overallRatingAverage: number;
      candidateSubmissionQualityAverage: number;
      timeToPlacementAverage: number;
    };
  };
};

export type RoleDossierQueryVariables = Exact<{
  roleId: Scalars['String'];
}>;

export type RoleDossierQuery = {
  __typename?: 'Query';
  roleDossier: {
    __typename?: 'RoleDossierOutput';
    id: string;
    name: string;
    marketplaceVisibility?: string | null;
    searchStatus?: string | null;
    status?: string | null;
    description?: string | null;
    descriptionJSON?: string | null;
    createdAt: Date;
    quantity?: number | null;
    expectedFirstYearSalaryMin?: number | null;
    expectedFirstYearSalaryMax?: number | null;
    expectedFirstYearShareCount?: number | null;
    publicDossierUrl?: string | null;
    remoteWorkPolicy: RemoteWorkPolicyEnum;
    isDossierShareable: boolean;
    roleType: RoleTypeEnum;
    placementFee?: {
      __typename?: 'PlacementFeeOutput';
      amount: number;
      feeType: FeeScheduleTypeEnum;
    } | null;
    hiringManager?: {
      __typename?: 'Employee';
      id: string;
      user: { __typename?: 'User'; name: string };
    } | null;
    roleRequirements: Array<{
      __typename?: 'RoleRequirement';
      mustHave: boolean;
      id: string;
      prompt: string;
    }>;
    roleSeniority?: {
      __typename?: 'RoleSeniority';
      id: string;
      name: string;
    } | null;
    roleFunction?: {
      __typename?: 'RoleFunction';
      id: string;
      name: string;
    } | null;
    cities: Array<{
      __typename?: 'City';
      id: string;
      cityName: string;
      stateAbbrev: string;
    }>;
    employer: {
      __typename?: 'Employer';
      id: string;
      company: {
        __typename?: 'Company';
        name: string;
        logo?: string | null;
        logoUrl: string;
        linkedIn?: string | null;
        crunchbase?: string | null;
        website?: string | null;
        intro?: string | null;
        introJSON?: string | null;
        tagline?: string | null;
        overviewJSON?: string | null;
        revenue?: number | null;
        totalFunding?: number | null;
        yoyGrowth?: number | null;
        tam?: number | null;
        latestFundingRoundAmount?: number | null;
        latestFundingRoundType?: LatestFundingRoundTypeEnum | null;
        investors?: string | null;
        employeeCount?: string | null;
        teamMembers: Array<{
          __typename?: 'CompanyTeamMember';
          id: string;
          name: string;
          title: string;
          linkedin: string;
        }>;
        perks: Array<{
          __typename?: 'CompanyPerk';
          id: string;
          type: CompanyPerkEnum;
        }>;
        press: Array<{
          __typename?: 'CompanyPress';
          id: string;
          title: string;
          url: string;
        }>;
        companyStage?: {
          __typename?: 'CompanyStage';
          id: string;
          name: string;
        } | null;
      };
    };
  };
};

export type SetUserNotificationSettingMutationVariables = Exact<{
  input:
    | Array<SetUserNotificationSettingInput>
    | SetUserNotificationSettingInput;
}>;

export type SetUserNotificationSettingMutation = {
  __typename?: 'Mutation';
  setUserNotificationSetting: boolean;
};

export type SuccessfulPlacementsByRecruiterQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type SuccessfulPlacementsByRecruiterQuery = {
  __typename?: 'Query';
  successfulPlacementsByRecruiter: number;
};

export type CalculateTimeToPlacementByRecruiterQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type CalculateTimeToPlacementByRecruiterQuery = {
  __typename?: 'Query';
  calculateTimeToPlacementByRecruiter: number;
};

export type TopRoleFunctionsQueryVariables = Exact<{
  recruiterId: Scalars['String'];
}>;

export type TopRoleFunctionsQuery = {
  __typename?: 'Query';
  topRoleFunctions: Array<{
    __typename?: 'TopRoleFunctions';
    id: string;
    name: string;
    placementsCount: number;
  }>;
};

export type UpdateUserTermsAndPolicyMutationVariables = Exact<{
  data: UpdateUserTermsAndPolicyInput;
}>;

export type UpdateUserTermsAndPolicyMutation = {
  __typename?: 'Mutation';
  updateUserTermsAndPolicy: { __typename?: 'User'; id: string };
};

export const ApiVersionDocument = gql`
  query ApiVersion {
    apiVersion
  }
`;

/**
 * __useApiVersionQuery__
 *
 * To run a query within a React component, call `useApiVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiVersionQuery,
    ApiVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiVersionQuery, ApiVersionQueryVariables>(
    ApiVersionDocument,
    options
  );
}
export function useApiVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiVersionQuery,
    ApiVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiVersionQuery, ApiVersionQueryVariables>(
    ApiVersionDocument,
    options
  );
}
export type ApiVersionQueryHookResult = ReturnType<typeof useApiVersionQuery>;
export type ApiVersionLazyQueryHookResult = ReturnType<
  typeof useApiVersionLazyQuery
>;
export type ApiVersionQueryResult = Apollo.QueryResult<
  ApiVersionQuery,
  ApiVersionQueryVariables
>;
export function refetchApiVersionQuery(variables?: ApiVersionQueryVariables) {
  return { query: ApiVersionDocument, variables: variables };
}
export const ClientCompanyStagesDocument = gql`
  query ClientCompanyStages($recruiterId: String!) {
    clientCompanyStages(recruiterId: $recruiterId) {
      name
      count
    }
  }
`;

/**
 * __useClientCompanyStagesQuery__
 *
 * To run a query within a React component, call `useClientCompanyStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCompanyStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCompanyStagesQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useClientCompanyStagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientCompanyStagesQuery,
    ClientCompanyStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientCompanyStagesQuery,
    ClientCompanyStagesQueryVariables
  >(ClientCompanyStagesDocument, options);
}
export function useClientCompanyStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientCompanyStagesQuery,
    ClientCompanyStagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientCompanyStagesQuery,
    ClientCompanyStagesQueryVariables
  >(ClientCompanyStagesDocument, options);
}
export type ClientCompanyStagesQueryHookResult = ReturnType<
  typeof useClientCompanyStagesQuery
>;
export type ClientCompanyStagesLazyQueryHookResult = ReturnType<
  typeof useClientCompanyStagesLazyQuery
>;
export type ClientCompanyStagesQueryResult = Apollo.QueryResult<
  ClientCompanyStagesQuery,
  ClientCompanyStagesQueryVariables
>;
export function refetchClientCompanyStagesQuery(
  variables: ClientCompanyStagesQueryVariables
) {
  return { query: ClientCompanyStagesDocument, variables: variables };
}
export const EndorsementsByRecruiterDocument = gql`
  query EndorsementsByRecruiter($recruiterId: String!) {
    endorsementsByRecruiter(recruiterId: $recruiterId) {
      id
      endorserTitle
      endorserCompany
      order
      endorsement
    }
  }
`;

/**
 * __useEndorsementsByRecruiterQuery__
 *
 * To run a query within a React component, call `useEndorsementsByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useEndorsementsByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEndorsementsByRecruiterQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useEndorsementsByRecruiterQuery(
  baseOptions: Apollo.QueryHookOptions<
    EndorsementsByRecruiterQuery,
    EndorsementsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EndorsementsByRecruiterQuery,
    EndorsementsByRecruiterQueryVariables
  >(EndorsementsByRecruiterDocument, options);
}
export function useEndorsementsByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EndorsementsByRecruiterQuery,
    EndorsementsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EndorsementsByRecruiterQuery,
    EndorsementsByRecruiterQueryVariables
  >(EndorsementsByRecruiterDocument, options);
}
export type EndorsementsByRecruiterQueryHookResult = ReturnType<
  typeof useEndorsementsByRecruiterQuery
>;
export type EndorsementsByRecruiterLazyQueryHookResult = ReturnType<
  typeof useEndorsementsByRecruiterLazyQuery
>;
export type EndorsementsByRecruiterQueryResult = Apollo.QueryResult<
  EndorsementsByRecruiterQuery,
  EndorsementsByRecruiterQueryVariables
>;
export function refetchEndorsementsByRecruiterQuery(
  variables: EndorsementsByRecruiterQueryVariables
) {
  return { query: EndorsementsByRecruiterDocument, variables: variables };
}
export const IndustriesDocument = gql`
  query Industries {
    industries {
      id
      name
    }
  }
`;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndustriesQuery,
    IndustriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndustriesQuery, IndustriesQueryVariables>(
    IndustriesDocument,
    options
  );
}
export function useIndustriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndustriesQuery,
    IndustriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(
    IndustriesDocument,
    options
  );
}
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<
  typeof useIndustriesLazyQuery
>;
export type IndustriesQueryResult = Apollo.QueryResult<
  IndustriesQuery,
  IndustriesQueryVariables
>;
export function refetchIndustriesQuery(variables?: IndustriesQueryVariables) {
  return { query: IndustriesDocument, variables: variables };
}
export const InviteTeamMemberDocument = gql`
  mutation InviteTeamMember($data: InviteTeamMemberInput!) {
    inviteTeamMember(data: $data)
  }
`;
export type InviteTeamMemberMutationFn = Apollo.MutationFunction<
  InviteTeamMemberMutation,
  InviteTeamMemberMutationVariables
>;

/**
 * __useInviteTeamMemberMutation__
 *
 * To run a mutation, you first call `useInviteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeamMemberMutation, { data, loading, error }] = useInviteTeamMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteTeamMemberMutation,
    InviteTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteTeamMemberMutation,
    InviteTeamMemberMutationVariables
  >(InviteTeamMemberDocument, options);
}
export type InviteTeamMemberMutationHookResult = ReturnType<
  typeof useInviteTeamMemberMutation
>;
export type InviteTeamMemberMutationResult =
  Apollo.MutationResult<InviteTeamMemberMutation>;
export type InviteTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  InviteTeamMemberMutation,
  InviteTeamMemberMutationVariables
>;
export const ListUserNotificationSettingDocument = gql`
  query ListUserNotificationSetting {
    listUserNotificationSetting {
      id
      type
      channel
      enabled
    }
  }
`;

/**
 * __useListUserNotificationSettingQuery__
 *
 * To run a query within a React component, call `useListUserNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserNotificationSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserNotificationSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserNotificationSettingQuery,
    ListUserNotificationSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListUserNotificationSettingQuery,
    ListUserNotificationSettingQueryVariables
  >(ListUserNotificationSettingDocument, options);
}
export function useListUserNotificationSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserNotificationSettingQuery,
    ListUserNotificationSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListUserNotificationSettingQuery,
    ListUserNotificationSettingQueryVariables
  >(ListUserNotificationSettingDocument, options);
}
export type ListUserNotificationSettingQueryHookResult = ReturnType<
  typeof useListUserNotificationSettingQuery
>;
export type ListUserNotificationSettingLazyQueryHookResult = ReturnType<
  typeof useListUserNotificationSettingLazyQuery
>;
export type ListUserNotificationSettingQueryResult = Apollo.QueryResult<
  ListUserNotificationSettingQuery,
  ListUserNotificationSettingQueryVariables
>;
export function refetchListUserNotificationSettingQuery(
  variables?: ListUserNotificationSettingQueryVariables
) {
  return { query: ListUserNotificationSettingDocument, variables: variables };
}
export const PlacementCompaniesDocument = gql`
  query PlacementCompanies($recruiterId: String!) {
    placementCompanies(recruiterId: $recruiterId) {
      id
      name
      logoUrl
    }
  }
`;

/**
 * __usePlacementCompaniesQuery__
 *
 * To run a query within a React component, call `usePlacementCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementCompaniesQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function usePlacementCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >(PlacementCompaniesDocument, options);
}
export function usePlacementCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlacementCompaniesQuery,
    PlacementCompaniesQueryVariables
  >(PlacementCompaniesDocument, options);
}
export type PlacementCompaniesQueryHookResult = ReturnType<
  typeof usePlacementCompaniesQuery
>;
export type PlacementCompaniesLazyQueryHookResult = ReturnType<
  typeof usePlacementCompaniesLazyQuery
>;
export type PlacementCompaniesQueryResult = Apollo.QueryResult<
  PlacementCompaniesQuery,
  PlacementCompaniesQueryVariables
>;
export function refetchPlacementCompaniesQuery(
  variables: PlacementCompaniesQueryVariables
) {
  return { query: PlacementCompaniesDocument, variables: variables };
}
export const PlacementsByRecruiterDocument = gql`
  query PlacementsByRecruiter($recruiterId: String!) {
    placementsByRecruiter(recruiterId: $recruiterId) {
      id
      firstYearBaseSalary
      tenureMonths
      yoeMonth
      candidateId
      companyStageId
      companyStage {
        id
        name
      }
      city {
        id
        cityName
        stateAbbrev
      }
      company {
        id
        name
        logoUrl
        industry {
          name
        }
      }
      roleSeniority {
        id
        name
      }
      roleTitle {
        id
        name
      }
      roleFunction {
        id
        name
      }
      linkedIn
      placedAt
    }
  }
`;

/**
 * __usePlacementsByRecruiterQuery__
 *
 * To run a query within a React component, call `usePlacementsByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementsByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementsByRecruiterQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function usePlacementsByRecruiterQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlacementsByRecruiterQuery,
    PlacementsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PlacementsByRecruiterQuery,
    PlacementsByRecruiterQueryVariables
  >(PlacementsByRecruiterDocument, options);
}
export function usePlacementsByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlacementsByRecruiterQuery,
    PlacementsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PlacementsByRecruiterQuery,
    PlacementsByRecruiterQueryVariables
  >(PlacementsByRecruiterDocument, options);
}
export type PlacementsByRecruiterQueryHookResult = ReturnType<
  typeof usePlacementsByRecruiterQuery
>;
export type PlacementsByRecruiterLazyQueryHookResult = ReturnType<
  typeof usePlacementsByRecruiterLazyQuery
>;
export type PlacementsByRecruiterQueryResult = Apollo.QueryResult<
  PlacementsByRecruiterQuery,
  PlacementsByRecruiterQueryVariables
>;
export function refetchPlacementsByRecruiterQuery(
  variables: PlacementsByRecruiterQueryVariables
) {
  return { query: PlacementsByRecruiterDocument, variables: variables };
}
export const ProfileOnboardingStepsDocument = gql`
  query ProfileOnboardingSteps {
    profileOnboardingSteps {
      name
      isCompleted
    }
  }
`;

/**
 * __useProfileOnboardingStepsQuery__
 *
 * To run a query within a React component, call `useProfileOnboardingStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileOnboardingStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileOnboardingStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileOnboardingStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileOnboardingStepsQuery,
    ProfileOnboardingStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileOnboardingStepsQuery,
    ProfileOnboardingStepsQueryVariables
  >(ProfileOnboardingStepsDocument, options);
}
export function useProfileOnboardingStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileOnboardingStepsQuery,
    ProfileOnboardingStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileOnboardingStepsQuery,
    ProfileOnboardingStepsQueryVariables
  >(ProfileOnboardingStepsDocument, options);
}
export type ProfileOnboardingStepsQueryHookResult = ReturnType<
  typeof useProfileOnboardingStepsQuery
>;
export type ProfileOnboardingStepsLazyQueryHookResult = ReturnType<
  typeof useProfileOnboardingStepsLazyQuery
>;
export type ProfileOnboardingStepsQueryResult = Apollo.QueryResult<
  ProfileOnboardingStepsQuery,
  ProfileOnboardingStepsQueryVariables
>;
export function refetchProfileOnboardingStepsQuery(
  variables?: ProfileOnboardingStepsQueryVariables
) {
  return { query: ProfileOnboardingStepsDocument, variables: variables };
}
export const RecruiterByIdDocument = gql`
  query RecruiterById($id: String!) {
    recruiterById(id: $id) {
      id
      bio
      bioJSON
      linkedIn
      title
      manualTimeToPlacement
      searchPreference
      location {
        id
        cityName
      }
      user {
        id
        email
        name
        firstName
        lastName
        phoneNumber
        photoUrl
        photo
      }
      agency {
        name
        website
      }
      timezone {
        timezoneName
        timezoneAbbrev
        timezoneOffset
      }
      cities {
        id
        cityName
      }
      roleFunctions {
        id
        name
      }
      location {
        cityName
        stateAbbrev
      }
      maxPricePercent
      minPricePercent
      schedulingLink15Min
      schedulingLink30Min
      schedulingLink60Min
    }
  }
`;

/**
 * __useRecruiterByIdQuery__
 *
 * To run a query within a React component, call `useRecruiterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecruiterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterByIdQuery,
    RecruiterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecruiterByIdQuery, RecruiterByIdQueryVariables>(
    RecruiterByIdDocument,
    options
  );
}
export function useRecruiterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterByIdQuery,
    RecruiterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecruiterByIdQuery, RecruiterByIdQueryVariables>(
    RecruiterByIdDocument,
    options
  );
}
export type RecruiterByIdQueryHookResult = ReturnType<
  typeof useRecruiterByIdQuery
>;
export type RecruiterByIdLazyQueryHookResult = ReturnType<
  typeof useRecruiterByIdLazyQuery
>;
export type RecruiterByIdQueryResult = Apollo.QueryResult<
  RecruiterByIdQuery,
  RecruiterByIdQueryVariables
>;
export function refetchRecruiterByIdQuery(
  variables: RecruiterByIdQueryVariables
) {
  return { query: RecruiterByIdDocument, variables: variables };
}
export const RecruiterCommonIndustriesDocument = gql`
  query RecruiterCommonIndustries($recruiterId: String!) {
    recruiterCommonIndustries(recruiterId: $recruiterId) {
      id
      name
      placementsCount
    }
  }
`;

/**
 * __useRecruiterCommonIndustriesQuery__
 *
 * To run a query within a React component, call `useRecruiterCommonIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterCommonIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterCommonIndustriesQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterCommonIndustriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterCommonIndustriesQuery,
    RecruiterCommonIndustriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterCommonIndustriesQuery,
    RecruiterCommonIndustriesQueryVariables
  >(RecruiterCommonIndustriesDocument, options);
}
export function useRecruiterCommonIndustriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterCommonIndustriesQuery,
    RecruiterCommonIndustriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterCommonIndustriesQuery,
    RecruiterCommonIndustriesQueryVariables
  >(RecruiterCommonIndustriesDocument, options);
}
export type RecruiterCommonIndustriesQueryHookResult = ReturnType<
  typeof useRecruiterCommonIndustriesQuery
>;
export type RecruiterCommonIndustriesLazyQueryHookResult = ReturnType<
  typeof useRecruiterCommonIndustriesLazyQuery
>;
export type RecruiterCommonIndustriesQueryResult = Apollo.QueryResult<
  RecruiterCommonIndustriesQuery,
  RecruiterCommonIndustriesQueryVariables
>;
export function refetchRecruiterCommonIndustriesQuery(
  variables: RecruiterCommonIndustriesQueryVariables
) {
  return { query: RecruiterCommonIndustriesDocument, variables: variables };
}
export const RecruiterTopSeniorityDocument = gql`
  query RecruiterTopSeniority($recruiterId: String!) {
    recruiterTopSeniority(recruiterId: $recruiterId) {
      id
      name
      placementsCount
    }
  }
`;

/**
 * __useRecruiterTopSeniorityQuery__
 *
 * To run a query within a React component, call `useRecruiterTopSeniorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterTopSeniorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterTopSeniorityQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterTopSeniorityQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterTopSeniorityQuery,
    RecruiterTopSeniorityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterTopSeniorityQuery,
    RecruiterTopSeniorityQueryVariables
  >(RecruiterTopSeniorityDocument, options);
}
export function useRecruiterTopSeniorityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterTopSeniorityQuery,
    RecruiterTopSeniorityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterTopSeniorityQuery,
    RecruiterTopSeniorityQueryVariables
  >(RecruiterTopSeniorityDocument, options);
}
export type RecruiterTopSeniorityQueryHookResult = ReturnType<
  typeof useRecruiterTopSeniorityQuery
>;
export type RecruiterTopSeniorityLazyQueryHookResult = ReturnType<
  typeof useRecruiterTopSeniorityLazyQuery
>;
export type RecruiterTopSeniorityQueryResult = Apollo.QueryResult<
  RecruiterTopSeniorityQuery,
  RecruiterTopSeniorityQueryVariables
>;
export function refetchRecruiterTopSeniorityQuery(
  variables: RecruiterTopSeniorityQueryVariables
) {
  return { query: RecruiterTopSeniorityDocument, variables: variables };
}
export const RecruiterCommonPlacedInstitutionsDocument = gql`
  query RecruiterCommonPlacedInstitutions($recruiterId: String!) {
    recruiterCommonPlacedInstitutions(recruiterId: $recruiterId) {
      id
      name
      placementsCount
    }
  }
`;

/**
 * __useRecruiterCommonPlacedInstitutionsQuery__
 *
 * To run a query within a React component, call `useRecruiterCommonPlacedInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterCommonPlacedInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterCommonPlacedInstitutionsQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterCommonPlacedInstitutionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >(RecruiterCommonPlacedInstitutionsDocument, options);
}
export function useRecruiterCommonPlacedInstitutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterCommonPlacedInstitutionsQuery,
    RecruiterCommonPlacedInstitutionsQueryVariables
  >(RecruiterCommonPlacedInstitutionsDocument, options);
}
export type RecruiterCommonPlacedInstitutionsQueryHookResult = ReturnType<
  typeof useRecruiterCommonPlacedInstitutionsQuery
>;
export type RecruiterCommonPlacedInstitutionsLazyQueryHookResult = ReturnType<
  typeof useRecruiterCommonPlacedInstitutionsLazyQuery
>;
export type RecruiterCommonPlacedInstitutionsQueryResult = Apollo.QueryResult<
  RecruiterCommonPlacedInstitutionsQuery,
  RecruiterCommonPlacedInstitutionsQueryVariables
>;
export function refetchRecruiterCommonPlacedInstitutionsQuery(
  variables: RecruiterCommonPlacedInstitutionsQueryVariables
) {
  return {
    query: RecruiterCommonPlacedInstitutionsDocument,
    variables: variables,
  };
}
export const RecruiterPlacementAverageTenureDocument = gql`
  query RecruiterPlacementAverageTenure($recruiterId: String!) {
    placementAverageTenure(recruiterId: $recruiterId)
  }
`;

/**
 * __useRecruiterPlacementAverageTenureQuery__
 *
 * To run a query within a React component, call `useRecruiterPlacementAverageTenureQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterPlacementAverageTenureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterPlacementAverageTenureQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterPlacementAverageTenureQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >(RecruiterPlacementAverageTenureDocument, options);
}
export function useRecruiterPlacementAverageTenureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterPlacementAverageTenureQuery,
    RecruiterPlacementAverageTenureQueryVariables
  >(RecruiterPlacementAverageTenureDocument, options);
}
export type RecruiterPlacementAverageTenureQueryHookResult = ReturnType<
  typeof useRecruiterPlacementAverageTenureQuery
>;
export type RecruiterPlacementAverageTenureLazyQueryHookResult = ReturnType<
  typeof useRecruiterPlacementAverageTenureLazyQuery
>;
export type RecruiterPlacementAverageTenureQueryResult = Apollo.QueryResult<
  RecruiterPlacementAverageTenureQuery,
  RecruiterPlacementAverageTenureQueryVariables
>;
export function refetchRecruiterPlacementAverageTenureQuery(
  variables: RecruiterPlacementAverageTenureQueryVariables
) {
  return {
    query: RecruiterPlacementAverageTenureDocument,
    variables: variables,
  };
}
export const RecruiterPlacementGeographiesDocument = gql`
  query RecruiterPlacementGeographies($recruiterId: String!) {
    recruiterPlacementGeographies(recruiterId: $recruiterId) {
      cityCode
      cityName
      placementsCount
      longitude
      latitude
    }
  }
`;

/**
 * __useRecruiterPlacementGeographiesQuery__
 *
 * To run a query within a React component, call `useRecruiterPlacementGeographiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterPlacementGeographiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterPlacementGeographiesQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useRecruiterPlacementGeographiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >(RecruiterPlacementGeographiesDocument, options);
}
export function useRecruiterPlacementGeographiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecruiterPlacementGeographiesQuery,
    RecruiterPlacementGeographiesQueryVariables
  >(RecruiterPlacementGeographiesDocument, options);
}
export type RecruiterPlacementGeographiesQueryHookResult = ReturnType<
  typeof useRecruiterPlacementGeographiesQuery
>;
export type RecruiterPlacementGeographiesLazyQueryHookResult = ReturnType<
  typeof useRecruiterPlacementGeographiesLazyQuery
>;
export type RecruiterPlacementGeographiesQueryResult = Apollo.QueryResult<
  RecruiterPlacementGeographiesQuery,
  RecruiterPlacementGeographiesQueryVariables
>;
export function refetchRecruiterPlacementGeographiesQuery(
  variables: RecruiterPlacementGeographiesQueryVariables
) {
  return { query: RecruiterPlacementGeographiesDocument, variables: variables };
}
export const RemoveTeamMemberDocument = gql`
  mutation RemoveTeamMember($userId: String!) {
    removeTeamMember(userId: $userId)
  }
`;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<
  RemoveTeamMemberMutation,
  RemoveTeamMemberMutationVariables
>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeamMemberMutation,
    RemoveTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTeamMemberMutation,
    RemoveTeamMemberMutationVariables
  >(RemoveTeamMemberDocument, options);
}
export type RemoveTeamMemberMutationHookResult = ReturnType<
  typeof useRemoveTeamMemberMutation
>;
export type RemoveTeamMemberMutationResult =
  Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeamMemberMutation,
  RemoveTeamMemberMutationVariables
>;
export const SendReportRecruiterReviewEmailDocument = gql`
  query SendReportRecruiterReviewEmail(
    $input: SendReportRecruiterReviewInput!
  ) {
    sendReportRecruiterReviewEmail(input: $input)
  }
`;

/**
 * __useSendReportRecruiterReviewEmailQuery__
 *
 * To run a query within a React component, call `useSendReportRecruiterReviewEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendReportRecruiterReviewEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendReportRecruiterReviewEmailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendReportRecruiterReviewEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    SendReportRecruiterReviewEmailQuery,
    SendReportRecruiterReviewEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SendReportRecruiterReviewEmailQuery,
    SendReportRecruiterReviewEmailQueryVariables
  >(SendReportRecruiterReviewEmailDocument, options);
}
export function useSendReportRecruiterReviewEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendReportRecruiterReviewEmailQuery,
    SendReportRecruiterReviewEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SendReportRecruiterReviewEmailQuery,
    SendReportRecruiterReviewEmailQueryVariables
  >(SendReportRecruiterReviewEmailDocument, options);
}
export type SendReportRecruiterReviewEmailQueryHookResult = ReturnType<
  typeof useSendReportRecruiterReviewEmailQuery
>;
export type SendReportRecruiterReviewEmailLazyQueryHookResult = ReturnType<
  typeof useSendReportRecruiterReviewEmailLazyQuery
>;
export type SendReportRecruiterReviewEmailQueryResult = Apollo.QueryResult<
  SendReportRecruiterReviewEmailQuery,
  SendReportRecruiterReviewEmailQueryVariables
>;
export function refetchSendReportRecruiterReviewEmailQuery(
  variables: SendReportRecruiterReviewEmailQueryVariables
) {
  return {
    query: SendReportRecruiterReviewEmailDocument,
    variables: variables,
  };
}
export const ReviewsByRecruiterDocument = gql`
  query ReviewsByRecruiter($recruiterId: String!) {
    reviewsByRecruiter(recruiterId: $recruiterId) {
      searchReviews {
        id
        createdAt
        communication
        timeliness
        overallRating
        candidateSubmissionQuality
        timeToPlacement
        review
        recruiterId
        searchAgreementRole {
          role {
            name
          }
        }
        reviewedByEmployee {
          employer {
            company {
              name
            }
          }
        }
      }
      reviewAverages {
        comunicationAverage
        timelessAverage
        overallRatingAverage
        candidateSubmissionQualityAverage
        timeToPlacementAverage
      }
    }
  }
`;

/**
 * __useReviewsByRecruiterQuery__
 *
 * To run a query within a React component, call `useReviewsByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsByRecruiterQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useReviewsByRecruiterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewsByRecruiterQuery,
    ReviewsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReviewsByRecruiterQuery,
    ReviewsByRecruiterQueryVariables
  >(ReviewsByRecruiterDocument, options);
}
export function useReviewsByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewsByRecruiterQuery,
    ReviewsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReviewsByRecruiterQuery,
    ReviewsByRecruiterQueryVariables
  >(ReviewsByRecruiterDocument, options);
}
export type ReviewsByRecruiterQueryHookResult = ReturnType<
  typeof useReviewsByRecruiterQuery
>;
export type ReviewsByRecruiterLazyQueryHookResult = ReturnType<
  typeof useReviewsByRecruiterLazyQuery
>;
export type ReviewsByRecruiterQueryResult = Apollo.QueryResult<
  ReviewsByRecruiterQuery,
  ReviewsByRecruiterQueryVariables
>;
export function refetchReviewsByRecruiterQuery(
  variables: ReviewsByRecruiterQueryVariables
) {
  return { query: ReviewsByRecruiterDocument, variables: variables };
}
export const RoleDossierQueryDocument = gql`
  query RoleDossierQuery($roleId: String!) {
    roleDossier(roleId: $roleId) {
      id
      name
      marketplaceVisibility
      searchStatus
      placementFee {
        amount
        feeType
      }
      status
      description
      descriptionJSON
      createdAt
      quantity
      expectedFirstYearSalaryMin
      expectedFirstYearSalaryMax
      expectedFirstYearShareCount
      publicDossierUrl
      remoteWorkPolicy
      isDossierShareable
      roleType
      hiringManager {
        id
        user {
          name
        }
      }
      roleRequirements {
        mustHave
        id
        prompt
      }
      roleSeniority {
        id
        name
      }
      roleFunction {
        id
        name
      }
      cities {
        id
        cityName
        stateAbbrev
      }
      employer {
        id
        company {
          name
          logo
          logoUrl
          linkedIn
          crunchbase
          website
          intro
          introJSON
          tagline
          overviewJSON
          revenue
          totalFunding
          yoyGrowth
          tam
          latestFundingRoundAmount
          latestFundingRoundType
          investors
          employeeCount
          teamMembers {
            id
            name
            title
            linkedin
          }
          perks {
            id
            type
          }
          press {
            id
            title
            url
          }
          companyStage {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useRoleDossierQuery__
 *
 * To run a query within a React component, call `useRoleDossierQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleDossierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleDossierQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRoleDossierQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoleDossierQuery,
    RoleDossierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleDossierQuery, RoleDossierQueryVariables>(
    RoleDossierQueryDocument,
    options
  );
}
export function useRoleDossierQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoleDossierQuery,
    RoleDossierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleDossierQuery, RoleDossierQueryVariables>(
    RoleDossierQueryDocument,
    options
  );
}
export type RoleDossierQueryHookResult = ReturnType<typeof useRoleDossierQuery>;
export type RoleDossierQueryLazyQueryHookResult = ReturnType<
  typeof useRoleDossierQueryLazyQuery
>;
export type RoleDossierQueryQueryResult = Apollo.QueryResult<
  RoleDossierQuery,
  RoleDossierQueryVariables
>;
export function refetchRoleDossierQuery(variables: RoleDossierQueryVariables) {
  return { query: RoleDossierQueryDocument, variables: variables };
}
export const SetUserNotificationSettingDocument = gql`
  mutation SetUserNotificationSetting(
    $input: [SetUserNotificationSettingInput!]!
  ) {
    setUserNotificationSetting(input: $input)
  }
`;
export type SetUserNotificationSettingMutationFn = Apollo.MutationFunction<
  SetUserNotificationSettingMutation,
  SetUserNotificationSettingMutationVariables
>;

/**
 * __useSetUserNotificationSettingMutation__
 *
 * To run a mutation, you first call `useSetUserNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNotificationSettingMutation, { data, loading, error }] = useSetUserNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserNotificationSettingMutation,
    SetUserNotificationSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserNotificationSettingMutation,
    SetUserNotificationSettingMutationVariables
  >(SetUserNotificationSettingDocument, options);
}
export type SetUserNotificationSettingMutationHookResult = ReturnType<
  typeof useSetUserNotificationSettingMutation
>;
export type SetUserNotificationSettingMutationResult =
  Apollo.MutationResult<SetUserNotificationSettingMutation>;
export type SetUserNotificationSettingMutationOptions =
  Apollo.BaseMutationOptions<
    SetUserNotificationSettingMutation,
    SetUserNotificationSettingMutationVariables
  >;
export const SuccessfulPlacementsByRecruiterDocument = gql`
  query SuccessfulPlacementsByRecruiter($recruiterId: String!) {
    successfulPlacementsByRecruiter(recruiterId: $recruiterId)
  }
`;

/**
 * __useSuccessfulPlacementsByRecruiterQuery__
 *
 * To run a query within a React component, call `useSuccessfulPlacementsByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuccessfulPlacementsByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuccessfulPlacementsByRecruiterQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useSuccessfulPlacementsByRecruiterQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuccessfulPlacementsByRecruiterQuery,
    SuccessfulPlacementsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuccessfulPlacementsByRecruiterQuery,
    SuccessfulPlacementsByRecruiterQueryVariables
  >(SuccessfulPlacementsByRecruiterDocument, options);
}
export function useSuccessfulPlacementsByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuccessfulPlacementsByRecruiterQuery,
    SuccessfulPlacementsByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuccessfulPlacementsByRecruiterQuery,
    SuccessfulPlacementsByRecruiterQueryVariables
  >(SuccessfulPlacementsByRecruiterDocument, options);
}
export type SuccessfulPlacementsByRecruiterQueryHookResult = ReturnType<
  typeof useSuccessfulPlacementsByRecruiterQuery
>;
export type SuccessfulPlacementsByRecruiterLazyQueryHookResult = ReturnType<
  typeof useSuccessfulPlacementsByRecruiterLazyQuery
>;
export type SuccessfulPlacementsByRecruiterQueryResult = Apollo.QueryResult<
  SuccessfulPlacementsByRecruiterQuery,
  SuccessfulPlacementsByRecruiterQueryVariables
>;
export function refetchSuccessfulPlacementsByRecruiterQuery(
  variables: SuccessfulPlacementsByRecruiterQueryVariables
) {
  return {
    query: SuccessfulPlacementsByRecruiterDocument,
    variables: variables,
  };
}
export const CalculateTimeToPlacementByRecruiterDocument = gql`
  query CalculateTimeToPlacementByRecruiter($recruiterId: String!) {
    calculateTimeToPlacementByRecruiter(recruiterId: $recruiterId)
  }
`;

/**
 * __useCalculateTimeToPlacementByRecruiterQuery__
 *
 * To run a query within a React component, call `useCalculateTimeToPlacementByRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateTimeToPlacementByRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateTimeToPlacementByRecruiterQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useCalculateTimeToPlacementByRecruiterQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateTimeToPlacementByRecruiterQuery,
    CalculateTimeToPlacementByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalculateTimeToPlacementByRecruiterQuery,
    CalculateTimeToPlacementByRecruiterQueryVariables
  >(CalculateTimeToPlacementByRecruiterDocument, options);
}
export function useCalculateTimeToPlacementByRecruiterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateTimeToPlacementByRecruiterQuery,
    CalculateTimeToPlacementByRecruiterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalculateTimeToPlacementByRecruiterQuery,
    CalculateTimeToPlacementByRecruiterQueryVariables
  >(CalculateTimeToPlacementByRecruiterDocument, options);
}
export type CalculateTimeToPlacementByRecruiterQueryHookResult = ReturnType<
  typeof useCalculateTimeToPlacementByRecruiterQuery
>;
export type CalculateTimeToPlacementByRecruiterLazyQueryHookResult = ReturnType<
  typeof useCalculateTimeToPlacementByRecruiterLazyQuery
>;
export type CalculateTimeToPlacementByRecruiterQueryResult = Apollo.QueryResult<
  CalculateTimeToPlacementByRecruiterQuery,
  CalculateTimeToPlacementByRecruiterQueryVariables
>;
export function refetchCalculateTimeToPlacementByRecruiterQuery(
  variables: CalculateTimeToPlacementByRecruiterQueryVariables
) {
  return {
    query: CalculateTimeToPlacementByRecruiterDocument,
    variables: variables,
  };
}
export const TopRoleFunctionsDocument = gql`
  query TopRoleFunctions($recruiterId: String!) {
    topRoleFunctions(recruiterId: $recruiterId) {
      id
      name
      placementsCount
    }
  }
`;

/**
 * __useTopRoleFunctionsQuery__
 *
 * To run a query within a React component, call `useTopRoleFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopRoleFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopRoleFunctionsQuery({
 *   variables: {
 *      recruiterId: // value for 'recruiterId'
 *   },
 * });
 */
export function useTopRoleFunctionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopRoleFunctionsQuery,
    TopRoleFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopRoleFunctionsQuery, TopRoleFunctionsQueryVariables>(
    TopRoleFunctionsDocument,
    options
  );
}
export function useTopRoleFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopRoleFunctionsQuery,
    TopRoleFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopRoleFunctionsQuery,
    TopRoleFunctionsQueryVariables
  >(TopRoleFunctionsDocument, options);
}
export type TopRoleFunctionsQueryHookResult = ReturnType<
  typeof useTopRoleFunctionsQuery
>;
export type TopRoleFunctionsLazyQueryHookResult = ReturnType<
  typeof useTopRoleFunctionsLazyQuery
>;
export type TopRoleFunctionsQueryResult = Apollo.QueryResult<
  TopRoleFunctionsQuery,
  TopRoleFunctionsQueryVariables
>;
export function refetchTopRoleFunctionsQuery(
  variables: TopRoleFunctionsQueryVariables
) {
  return { query: TopRoleFunctionsDocument, variables: variables };
}
export const UpdateUserTermsAndPolicyDocument = gql`
  mutation UpdateUserTermsAndPolicy($data: UpdateUserTermsAndPolicyInput!) {
    updateUserTermsAndPolicy(data: $data) {
      id
    }
  }
`;
export type UpdateUserTermsAndPolicyMutationFn = Apollo.MutationFunction<
  UpdateUserTermsAndPolicyMutation,
  UpdateUserTermsAndPolicyMutationVariables
>;

/**
 * __useUpdateUserTermsAndPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateUserTermsAndPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTermsAndPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTermsAndPolicyMutation, { data, loading, error }] = useUpdateUserTermsAndPolicyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserTermsAndPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserTermsAndPolicyMutation,
    UpdateUserTermsAndPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserTermsAndPolicyMutation,
    UpdateUserTermsAndPolicyMutationVariables
  >(UpdateUserTermsAndPolicyDocument, options);
}
export type UpdateUserTermsAndPolicyMutationHookResult = ReturnType<
  typeof useUpdateUserTermsAndPolicyMutation
>;
export type UpdateUserTermsAndPolicyMutationResult =
  Apollo.MutationResult<UpdateUserTermsAndPolicyMutation>;
export type UpdateUserTermsAndPolicyMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserTermsAndPolicyMutation,
    UpdateUserTermsAndPolicyMutationVariables
  >;
