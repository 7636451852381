import { ArrowNarrowLeftIcon, MenuAlt2Icon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo, useRef } from 'react';

import Button from '../Button';
import HeaderTab from './HeaderTab';
import { useHeaderTabs } from './HeaderTabsProvider';
import { useSecondaryNav } from './SecondaryNavProvider';

interface TailwindHeaderProps {
  onOpenSidebarClick: () => void;
  title: string;
  showBackArrow?: boolean | string;
}

const TailwindHeader: React.VFC<TailwindHeaderProps> = (props) => {
  const { onOpenSidebarClick, title, showBackArrow } = props;
  const { back, push } = useRouter();
  const menuButton = useRef<HTMLButtonElement>(null);

  const { navigationItems, title: customTitle } = useSecondaryNav();
  const { tabs } = useHeaderTabs();

  const handleMenuButtonClick = (): void => {
    if (menuButton.current) {
      menuButton.current.blur();
    }
    onOpenSidebarClick();
  };

  const navigationItemsList = useMemo(() => {
    if (navigationItems.length) {
      const items: {
        extras: React.ReactNode[];
        buttons: React.ReactNode[];
        belows: React.ReactNode[];
      } = { buttons: [], extras: [], belows: [] };
      navigationItems.forEach((item) => {
        if (
          (item.type === 'primary' || item.type === 'secondary') &&
          !item.hidden
        ) {
          if (typeof item.action === 'function') {
            items.buttons.push(
              <Button
                key={item.label}
                buttonType={item.type}
                onClick={item.action}
                loading={item.loading}
                className="mx-0 px-3 py-1"
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            );
          }
          if (typeof item.action === 'string') {
            items.buttons.push(
              <Link key={item.label} href={item.action} prefetch={false}>
                <a
                  key={item.label}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <Button
                    key={item.label}
                    buttonType={item.type}
                    className="mx-0 px-3 py-1"
                  >
                    {item.label}
                  </Button>
                </a>
              </Link>
            );
          }
        }
        if (item.type === 'other') {
          items.extras.push(item.element);
        }
        if (item.type === 'below') {
          items.belows.push(item.element);
        }
      });
      return items;
    }
    return null;
  }, [navigationItems]);

  return (
    <>
      <div id="tailwind-head" className="sticky top-0 z-20">
        <div className="flex-shrink-0 flex shadow min-h-[4rem] bg-white">
          <button
            type="button"
            ref={menuButton}
            className="px-4 border-r border-gray-200 text-gray-500  md:hidden"
            onClick={handleMenuButtonClick}
            onMouseLeave={(): void => {
              if (menuButton.current) {
                menuButton.current.blur();
              }
            }}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex items-center gap-2 sm:justify-between flex-wrap md:flex-nowrap justify-start md:justify-end w-full px-2 sm:px-[60px] py-3.5 md:flex-row flex-col ">
            <div className="flex-1 flex flex-wrap md:whitespace-nowrap justify-between gap-2">
              <h1 className="flex sm:text-xl text-headraceBlack-700 font-bold">
                {showBackArrow && (
                  <button
                    type="button"
                    onClick={async (): Promise<void> => {
                      if (typeof showBackArrow === 'string') {
                        await push(showBackArrow);
                      } else {
                        back();
                      }
                    }}
                  >
                    <ArrowNarrowLeftIcon className="sm:mr-8 mr-2 sm:w-5 w-2 text-gray-900 hover:text-gray-700 cursor-pointer" />
                  </button>
                )}
                {customTitle || title}
              </h1>
              {navigationItemsList?.extras && (
                <div className="flex items-center gap-3">
                  {navigationItemsList.extras}
                </div>
              )}
            </div>
            {navigationItemsList?.buttons &&
            navigationItemsList?.buttons.length ? (
              <div className="flex items-center justify-center gap-3 md:ml-3 whitespace-nowrap flex-wrap">
                {navigationItemsList.buttons}
              </div>
            ) : null}
          </div>
        </div>
        {navigationItemsList?.belows && navigationItemsList.belows.length ? (
          <div className="sticky top-0 flex-shrink-0 flex sm:min-h-[4rem] bg-white shadow z-10">
            {navigationItemsList.belows}
          </div>
        ) : null}
      </div>
      {tabs.length > 0 && <HeaderTab />}
    </>
  );
};

export default TailwindHeader;
